import { GetBookings, getBookingsAmberFort } from '../services/api.js';

export const BookingRequest = () => ({
	type: 'FETCH_BOOKING_REQUEST',
});

export const BookingSuccess = (data) => ({
	type: 'FETCH_BOOKING_SUCCESS',
	payload: data,
});

export const BookingFailure = (error) => ({
	type: 'FETCH_BOOKING_FAILURE',
	payload: error,
});

export const setSubLocationDetails = (data) => ({
	type: 'SET_SUB_LOCATION_DETAILS',
	payload: data,
});

export const setSubLocationActivityType = (data) => ({
	 type:'SET_SUB_LOCATION_ACTIVITY_TYPE',
	 payload:data,
});

export const errorInfetchingLocationDetails = (data) => ({
	type: 'SET_LOCATION_DETAILS',
	payload: data,
});

export const setCurrentDate = (data) => ({
	type: 'SET_DATE',
	payload: data,
});

export const setLocationId = (data) => ({
	type: 'SET_LOCATION_ID',
	payload: data,
});

export const setLocationName = (data) => ({
	type: 'SET_LOCATION_NAME',
	payload: data,
});

export const setSubLocationName = (data) => ({
	type: 'SET_SUB_LOCATION_NAME',
	payload: data,
});



export const fetchData = (location_id, date, sub_location_id, transaction_status) => {
	// console.log("sub_location_id",sub_location_id);
	return async (dispatch) => {
		try {
			let data_retries = 0;
			dispatch(BookingSuccess([]));
			while (data_retries < 4) {
				dispatch(BookingRequest());
				await GetBookings(location_id, date, transaction_status)
					.then((response) => {
						const allBookingsFromThatLocation = response.data.booking;
						if (allBookingsFromThatLocation === null) {
							dispatch(BookingSuccess([]));
						} else {
							const bookingsAccordingToSubLocation = allBookingsFromThatLocation.filter((items) =>
								items.sub_location_id.includes(sub_location_id)
							);
							// console.log('booking list', bookingsAccordingToSubLocation);
							const sortedBookings = [...bookingsAccordingToSubLocation].sort((a, b) => {
								const statusOrder = ['video_uploaded', 'shot_completed', 'PENDING', 'deleted', 'video_shared'];
								const aIndex = statusOrder.indexOf(a.status);
								const bIndex = statusOrder.indexOf(b.status);
								return aIndex - bIndex;
							});
							dispatch(BookingSuccess(sortedBookings));
						}
						data_retries = 4;
					})
					.catch((err) => {
						data_retries++;
					});
			}
		} catch (err) {
			dispatch(BookingFailure(err));
		}
	};
};

export const fetchBookingsAmberFort=(location_id ,sub_location_id)=>{
	return async (dispatch) => {
		try {
			let data_retries = 0;
			dispatch(BookingSuccess([]));
			while (data_retries < 4) {
				dispatch(BookingRequest());
				await getBookingsAmberFort(location_id,sub_location_id)
					.then((response) => {
						const allBookingsFromThatLocation = response.data.shots;
						// console.log("allBookingsFromThatLocation :", allBookingsFromThatLocation);
						if (allBookingsFromThatLocation === null) {
							dispatch(BookingSuccess([]));
						} else {
							// const bookingsAccordingToSubLocation = allBookingsFromThatLocation.filter((items) =>
							// 	items.sub_location_id.includes(sub_location_id)
							// );
							// console.log('booking list', bookingsAccordingToSubLocation);
							// const sortedBookings = [...bookingsAccordingToSubLocation].sort((a, b) => {
							// 	const statusOrder = ['video_uploaded', 'shot_completed', 'PENDING', 'deleted', 'video_shared'];
							// 	const aIndex = statusOrder.indexOf(a.status);
							// 	const bIndex = statusOrder.indexOf(b.status);
							// 	return aIndex - bIndex;
							// });
							dispatch(BookingSuccess(allBookingsFromThatLocation));
						}
						data_retries = 4;
					})
					.catch((err) => {
						data_retries++;
					});
			}
		} catch (err) {
			dispatch(BookingFailure(err));
		}
	}
};
