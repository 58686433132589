import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { IoCloseSharp } from "react-icons/io5";
import { closeVideo, setVideoUrl } from "../actions/CustomerAction";
import { useSelector, useDispatch } from "react-redux";
import ReactHLS from "react-hls";
import assets from '../assets';


const VideoPlayerComp = ({setShowFullPortrait}) => {
    const playerRef = useRef(null);
    let customerData = useSelector((store) => store.customer);
    let dispatch = useDispatch();
    const [boostPlay, setBoostPlay] = useState(false);
    const [isPlayerReady, setIsPlayerReady] = useState(false);
   

  useEffect(()=>{
    let start_time = 0;
    let sub_location_name = customerData?.customer_details?.sub_location_name;
    if (sub_location_name === "Splash Bungee") {
          start_time = 22;
        }else{
          start_time = 0;
        }

    if(isPlayerReady){
      if (playerRef?.current) {
        playerRef?.current.seekTo(start_time, 'seconds');
      }
    }
  },[customerData,isPlayerReady ,boostPlay])
  
  const handlePlayerReady = (val) => {
    if(customerData?.customer_details?.location_name === "Splash Bungee"){
      setIsPlayerReady(true);
    }else{
      setIsPlayerReady(false);
    }
  };
  
  
  return (
     <>{customerData && customerData?.video_url  ?
          customerData?.customer_details?.video_orientation === "landscape" ? 
         <div className={`flex flex-col w-full`}>
         <div className={`flex flex-row mb-2 justify-between  mmd:flex-row-reverse  items-center gap-5 mmd:mb-4`}>
                        <div className='bg-red-400 rounded-full p-2 cursor-pointer'  onClick={() => {dispatch(closeVideo())}}><IoCloseSharp size={20}/></div>
                        <h1 className='text-xl'>{customerData?.customer_details?.customer_name}</h1>
                        <button onClick={()=>{setBoostPlay((prev)=> !prev)}} className={`bg-transparent ${!boostPlay ? "hover:bg-green-500 text-green-700 border-green-500 hover:border-transparent" :'hover:bg-blue-500 text-blue-700 border-blue-500 hover:border-transparent'}  font-semibold hover:text-white py-2 px-4 border brounded`}>
                           {boostPlay ? "Normal Player" : "Boost Player"}
                        </button>
          </div>
       
       {boostPlay ? customerData?.customer_details?.hls_uri.includes(
          "master.m3u8"
        ) ? (
          <ReactHLS
            ref={playerRef}
            url={customerData?.customer_details?.hls_uri}
            width="auto"
            height="100%"
            onLoadedData={handlePlayerReady}
            
          />
        ) : (
            <ReactPlayer
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              ref={playerRef}
              onContextMenu={(e) => e.preventDefault()}
              url={customerData?.customer_details?.hls_uri}
              className="react-player"
              controls={true}
              playing={true}
              width="auto"
              height="75vh"
              onReady={handlePlayerReady}
            />
          ) : 
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  ref={playerRef}
                  onContextMenu={(e) => e.preventDefault()}
                  url={customerData?.video_url}
                  className="react-player"
                  controls={true}
                  playing={true}
                  width="auto"
                  height="75vh"
                  onReady={handlePlayerReady}
        />}
         </div> 
        :
          <>
          <div className={`flex flex-col justify-between mmd:flex-row-reverse  items-center gap-5 mmd:mb-4`}>
                        <div className='bg-red-400 rounded-full p-2 cursor-pointer'  onClick={() => {dispatch(closeVideo())}}><IoCloseSharp size={20}/></div>
                        <h1 className='text-xl'>{customerData?.customer_details?.customer_name}</h1>
                        <button onClick={()=>{setBoostPlay((prev)=> !prev)}} className={`bg-transparent ${!boostPlay ? "hover:bg-green-500 text-green-700 border-green-500 hover:border-transparent" :'hover:bg-blue-500 text-blue-700 border-blue-500 hover:border-transparent'}  font-semibold hover:text-white py-2 px-4 border brounded`}>
                            {boostPlay ? "Normal Player" : "Boost Player"}
                        </button>
                       {setShowFullPortrait &&  <button onClick={(e)=>{
                          e.preventDefault();
                          setShowFullPortrait(true);
                        }} className={`bg-transparent text-base hover:bg-[#DBA979] text-[#DBA979] border-[#DBA979] hover:border-transparent font-semibold hover:text-white py-1.5 px-3 border brounded`}>
                            Portrait Mode
                        </button> }
          </div>
        
        {boostPlay ? customerData?.customer_details?.hls_uri.includes(
          "master.m3u8"
        ) ? (
          <ReactHLS
            ref={playerRef}
            url={customerData?.customer_details?.hls_uri}
            width="auto"
            height="100%"
            onLoadedData={handlePlayerReady}
            
          />
        ) : (
            <ReactPlayer
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              ref={playerRef}
              onContextMenu={(e) => e.preventDefault()}
              url={customerData?.customer_details?.hls_uri}
              className="react-player object-cover flex justify-center"
              controls={true}
              playing={true}  
              width="auto"
              height="100%"
              onReady={handlePlayerReady}
            />
          ) : 
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  ref={playerRef}
                  onContextMenu={(e) => e.preventDefault()}
                  url={customerData?.video_url}
                  className="react-player object-cover flex justify-center"
                  controls={true}
                  playing={true}
                  width="auto"
                  height="100%"
                  onReady={handlePlayerReady}
                  
                
        />}
          </> 

                
        :

              <div className="flex justify-center items-center flex-col text-center my-auto w-[500px]"> {/* Example Tailwind classes for centering */}
               <div className='w-[100%]'><img src={assets?.notFonund_drone} alt="notfound_drone" className='w-full object-cover'/></div> 
                <p>Please select a booking to start the video experience. </p>
              </div>
          }

    </>
  )
}

export default VideoPlayerComp