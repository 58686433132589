import { initializeApp } from 'firebase/app';
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from 'firebase/auth';
import { Logging } from '../services/api';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};



const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
	try {
		const res = await signInWithPopup(auth, googleProvider);
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const logInWithEmailAndPassword = async (email, password) => {
	return new Promise(async (resolve, reject) => {
	let payload = { a_info: 'getting logged in with email and password', is_success: false, response: '' };
	try {
		let startTime = new Date().getTime();
		const res = await signInWithEmailAndPassword(auth, email, password);
		let endTime = new Date().getTime();
		payload.elapsed_time = endTime - startTime;
		payload.response = res.body;
		payload.is_success = true;
		// console.log("res : " , res);
		resolve(res);
		await Logging({ payload, severity: 'INFO' });
	} catch (err) {
		console.error(err);
		payload.is_success = false;
		payload.response = err;
		reject(err);
		await Logging({ payload, severity: 'ERROR' });
		// alert(err.message);
		
	}
	});
};

const registerWithEmailAndPassword = async (name, email, password) => {
	let payload = { a_info: 'register with email and password', is_success: false, response: '' };
	try {
		let startTime = new Date().getTime();
		const res = await createUserWithEmailAndPassword(auth, email, password);
		let endTime = new Date().getTime();
		payload.response = res.body;
		payload.is_success = true;
		//console.log('response of Registerwithemailandpasswords: ' + payload);
		await Logging({ payload, severity: 'INFO' });
		const user = res.user;
	} catch (err) {
		console.error(err);
		payload.is_success = false;
		payload.response = err;
		//console.log(payload);
		await Logging({ payload, severity: 'ERROR' });
		//console.error(err);
		alert(err.message);
	}
};

const sendPasswordReset = async (email) => {
	let payload = { a_info: 'register with email and password', is_success: false, response: '' };
	try {
		let startTime = new Date().getTime();
		let res = await sendPasswordResetEmail(auth, email);
		let endTime = new Date().getTime();
		payload.response = res.body;
		payload.is_success = true;
		//console.log('response of sendPasswordReset ' + payload);
		await Logging({ payload, severity: 'INFO' });
		alert('Password reset link sent!');
	} catch (err) {
		console.error(err);
		payload.is_success = false;
		payload.response = err;
		//console.log(payload);
		await Logging({ payload, severity: 'ERROR' });
		//console.error(err);
		alert(err.message);
	}
};

const logoutFirebase = () => {
	signOut(auth);
	localStorage.removeItem("user_token");
	localStorage.removeItem("user_email");
	localStorage?.removeItem("user_location");
	// * Redirect the user to the home page
	window.location.href = "/";
};

export { auth, signInWithGoogle, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, logoutFirebase ,analytics};
