import axios from "axios";
const URL = "https://zndngsg9ih.execute-api.us-east-1.amazonaws.com";
const paymentServiceURL =
  "https://oeibf65v72.execute-api.us-east-1.amazonaws.com";
const locationServiceURL =
  "https://dgxwrq9ztf.execute-api.us-east-1.amazonaws.com";
const customerServiceURL =
  "https://umlks472ud.execute-api.us-east-1.amazonaws.com";
const videoServiceURL =
  "https://48fvzipydk.execute-api.us-east-1.amazonaws.com";
const lapTimingsServiceURL =
  "https://s7i94pfxkd.execute-api.us-east-1.amazonaws.com";
const pricingServiceURL =
  "https://f4lf7zh6l1.execute-api.us-east-1.amazonaws.com";
const videoEditingServiceURL =
  "https://8qj7ikv729.execute-api.ap-south-1.amazonaws.com";
const feedbackApiUrl=
  "https://asru374vkuge5tdlxyy67nkxqu0mxiex.lambda-url.us-east-1.on.aws";
 
const customerServiceBaseUrl = "https://umlks472ud.execute-api.us-east-1.amazonaws.com"

const API_TEST_STAGE = "test";
const API_PROD_STAGE = "dev";
const PRODUCTION_ENVIRONMENT = "production";
const DEVELOPMENT_ENVIRONMENT = "development";

var currentEnvironment = PRODUCTION_ENVIRONMENT; // Change the value to DEVELOPMENT_ENVIRONMENT during testing

const GetApiStage = () => {
  if (currentEnvironment === PRODUCTION_ENVIRONMENT) return API_PROD_STAGE;
  else return API_TEST_STAGE;
};

const getURL = () => {
  return `${URL}/${GetApiStage()}`;
};

const getPaymentServiceUrl = () => {
  return `${paymentServiceURL}/${GetApiStage()}`;
};

const getCustomerServiceURL = () => {
  return `${customerServiceURL}/${GetApiStage()}`;
};

const getVideoServiceUrl = () => {
  return `${videoServiceURL}/${GetApiStage()}`;
};

const getLapTimingsServiceUrl = () => {
  return `${lapTimingsServiceURL}/${GetApiStage()}`;
};

const getPricingServiceUrl = () => {
  return `${pricingServiceURL}/${GetApiStage()}`;
};

const getVideoEditingServiceUrl = () => {
  return `${videoEditingServiceURL}/${GetApiStage()}`;
};

const getLocationServiceURL = () => {
  return `${locationServiceURL}/${GetApiStage()}`;
};

export const SetEnvironment = (environment) => {
  if (environment == DEVELOPMENT_ENVIRONMENT)
    currentEnvironment = DEVELOPMENT_ENVIRONMENT;
};

export const Logging = async (postdata) => {
  try {
    return await axios.post(`${getURL()}/logging`, postdata);
  } catch (error) {
    console.log(error);
  }
};

export const GetLocationDetails = async (location_id) => {
  try {
    return await axios.get(
      `${getLocationServiceURL()}/locations/${location_id}`
    );
  } catch (err) {
    console.log(err);
  }
};

export const GetAllShotsInALocation = async (location_id) => {
  try {
    return await axios.get(
      `${getLocationServiceURL()}/locations/${location_id}/shots`
    );
  } catch (err) {
    console.log(err);
  }
};

export const GetAllSublocationsInALocation = async (location_id) => {
  try {
    return await axios.get(
      `${getLocationServiceURL()}/locations/${location_id}/sub_location`
    );
  } catch (err) {
    console.log("Error in getting sublocations : ", err);
  }
};

export const GetLapTimingDetails = async (booking_id) => {
  try {
    return await axios.get(
      `${getLapTimingsServiceUrl()}/lap_details/${booking_id}`
    );
  } catch (err) {
    return err;
  }
};

export const CheckCustomerExists = async (phoneNumber, name) => {
  try {
    return await axios.post(
      `${getCustomerServiceURL()}/customer_exists`,
      {
        phone_number: phoneNumber,
        customer_name: name,
      },
      {
        withCredentials: false,
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const GetCustomerDetails = async (payload) => {
  try {
    return await axios.post(
      `${getCustomerServiceURL()}/customer_details`,payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log("Error in fetching customer details : ", error);
  }
};

export const AddNewBooking = async (customerId, createBookingPayload) => {
  try {
    await axios.post(
      `${getCustomerServiceURL()}/customer_details/${customerId}/bookings`,
      createBookingPayload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log("Error in adding new booking : ", error);
  }
};

export const GetCustomerBookings = async (customer_id) => {
  try {
    return await axios.get(
      `${getCustomerServiceURL()}/customer_details/${customer_id}/bookings`,
      {}
    );
  } catch (error) {
    console.log("Error in fetching bookings : ", error);
  }
};

export const GetBookings = async (location_id, date, transaction_status) => {
  try {
    if (!transaction_status) {
      let val = await axios.get(`${getCustomerServiceURL()}/bookings`, {
        params: {
          location_id: `${location_id}`,
          created_at: `${date}`,
        },
      });
      return val;
    } else {
      let val = await axios.get(`${getCustomerServiceURL()}/bookings`, {
        params: {
          location_id: `${location_id}`,
          created_at: `${date}`,
          transaction_status: transaction_status,
        },
      });
      return val;
    }
  } catch (err) {
    console.log(err);
  }
};

export const GetGroups = async (sub_location_id, date) => {
  try {
    let val = await axios.get(
      `${getCustomerServiceURL()}/groups/sub_location/${sub_location_id}/date/${date}`
    );
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const deleteGroup = async (group_id) => {
  try {
    let val = await axios.delete(
      `${getCustomerServiceURL()}/groups/${group_id}`
    );
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const mergeGroups = async (data) => {
  try {
    let val = await axios.patch(`${getCustomerServiceURL()}/groups`, {
      groupIds: data.groupIds,
      newGroupName: data.newGroupName,
    });
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const removeBookingsFromGroup = async (group_id, booking_ids) => {
  try {
    let val = await axios.patch(
      `${getCustomerServiceURL()}/groups/${group_id}`,
      {
        bookingDetails: booking_ids,
      }
    );
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const createCashfreeOrder = async (order_details) => {
  try {
    let val = await axios.post(
      `${getPaymentServiceUrl()}/cashfree/create_order`,
      { order_details: order_details }
    );
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const getCashfreeOrderDetails = async (order_id) => {
  try {
    let val = await axios.get(`${getPaymentServiceUrl()}/cashfree/get_order`, {
      params: { order_id: order_id },
    });
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const fetchCouponDetails = async (sub_location_id) => {
  try {
    let val = await axios.get(
      `${getPricingServiceUrl()}/pricing/sublocations/${sub_location_id}`
    );
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateBookingDetails = async (
  customer_id,
  booking_id,
  refund_mode
) => {
  try {
    if (refund_mode != null && refund_mode != undefined && refund_mode != "")
      return await axios.patch(
        `${getCustomerServiceURL()}/customer_details/${customer_id}/bookings/${booking_id}`,
        {
          refund_mode: refund_mode,
        },
        {
          withCredentials: false,
        }
      );
    else {
      return await axios.patch(
        `${getCustomerServiceURL()}/customer_details/${customer_id}/bookings/${booking_id}`,
        {
          current_status: "video_shared",
        },
        {
          withCredentials: false,
        }
      );
    }
  } catch (err) {
    console.log("update booking details", err);
  }
};

export const Transactions = async (data) => {
  try {
    return await axios.post(`${getPaymentServiceUrl()}/payment`, data);
  } catch (err) {
    console.log(err);
  }
};

export const UpdateVideoDetails = async (video_id, video_uri) => {
  try {
    return await axios.patch(
      `${getVideoServiceUrl()}/videos/${video_id}`,
      {
        current_status: "video_shared",
        short_video_uri: video_uri,
      },
      {
        withCredentials: false,
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const GetAllVideosInSubLocation = async (sub_location_id, date) => {
  try {
    let val = await axios.get(
      `${getVideoServiceUrl()}/videos/sub_location/${sub_location_id}`,
      {
        params: {
          created_at: `${date}`,
        },
      }
    );
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const GetCameraDetails = async (camera_id) => {
  try {
    let response = await axios.get(`${videoServiceURL}/cameras/${camera_id}`);
    console.log("response", response.data.camera[0]);
    console.log("Camera Name is ", response.data.camera[0].camera_name);
    return response.data.camera[0].camera_name;
  } catch (err) {
    console.log(err);
  }
};

export const getCoupons_shotId = async (shot_id) => {
  try {
    let response = await axios.get(
      `${getPricingServiceUrl()}/pricing/${shot_id}/prices`
    );
    console.log(response);
    return response?.prices ?? [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getBookingsWithPayDone = async (
  start_date,
  end_date,
  sub_location_id
) => {
   


  try {
    let response = await axios.get(
      `${getPaymentServiceUrl()}/payment/get_list/${sub_location_id}`,
      {
        params: {
          start_date: start_date,
          end_date: end_date,
        },
      }
    );
    return response.data.transactionDetails;
  } catch (err) {
    return [];
  }
};

export const fetch_transactions_by_date_and_sub_location_id = async (
  sub_location_id,
  date
) => {
  try {
    let response = await axios.get(
      `${getPaymentServiceUrl()}/payment/sublocation/${sub_location_id}/${date}`
    );
    console.log("response fetchDataof transaction", response.data);
    return response.data.transaction_details;
  } catch (err) {
    return [];
  }
};

export const groupVideoEditing = async (bookings, cutomers) => {
  try {
    let response = await axios.post(
      `${getVideoEditingServiceUrl()}/group_video_editing`,
      {
        resource: "/group_video_editing",
        httpMethod: "POST",
        bookings: bookings,
        customers: cutomers,
      }
    );
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const VideoEditing = async (customer_id, booking_id) => {
  try {
    let response = await axios.post(
      `${getVideoEditingServiceUrl()}/multiple_videos_editing`,
      {
        resource: "/multiple_videos_editing",
        httpMethod: "POST",
        booking_id: booking_id,
        customer_id: customer_id,
      }
    );
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};


  //** Get Bookings With Date Range   */
export const GetBookingsWithDateRange = async (location_id, start_date, end_date) => {
   const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  if (endDate < startDate) {
      start_date = endDate;
      end_date = startDate;
  }

  try {
      let val = await axios.get(`${customerServiceURL}/dev/location/${location_id}/bookings`, {
          params: {
              start_date: start_date,
              end_date: end_date
          },
      });

      return val;
  } catch (err) {
      console.log(err);
      return err;
  }
};


  //** Get Feedback Questions */
  export const getFeedbackQuestions = async () => {
    try {
      let response = await axios.get(`${feedbackApiUrl}/feedback/question?mode=viewshot`);
       return response;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

   //** Post Feedback Questions */
   export const submitFeedback = async (obj) => {

    try {
      const response = await axios.post(`${feedbackApiUrl}/feedback`,  obj);
      return response;
    } catch (err) {
      return err;
    }
  };


   //** Create Group Api */
  export const createGroupApi = async (group_details) => {
    try {
      let val = await axios.post(`${getCustomerServiceURL()}/groups/create`, {
        groupDetails: group_details,
      });
      return val;
    } catch (err) {
      console.log(err);
    }
  };

   //** Post Retargetting Remark */
   export const RetargettingRemarkApi=async(data_obj ,  booking_id)=>{
    try {
      // let response =  await axios.post(`${getCustomerServiceURL()}/customer_details/${customerId}/bookings/${booking_id}`,comment,{headers: {"Content-Type": "application/json",},});
      // let response =  await axios.patch(`${customerServiceURL}/${GetApiStage()}/bookings/${booking_id}/customer_id/${customerId}`,comment,{headers: {"Content-Type": "application/json",},});
      let response =  await axios.patch(`${customerServiceURL}/${GetApiStage()}/retargeting_details/${booking_id}` , data_obj)
       return response;
    } catch (err) {
      console.log("api -error ;",err);
      return err;
    }
   };

      //** Get Report Bookings */
      export const GetReportBookings=async(location_id , start_date , end_date )=>{
        
        try {
          let response =  await axios.get(`${customerServiceURL}/${GetApiStage()}/location/${location_id}/bookings` , {
            params: {
              start_date,
              end_date
            }
          })
           return response;
        } catch (err) {
          console.log("api -error ;",err);
          return err;
        }
       };

      //** Get Sort Report Bookings */
      export const GetRetargetingReportBookings=async(sub_location_id , start_date , end_date )=>{
        
        try {
          let response =  await axios.get(`${customerServiceURL}/${GetApiStage()}/retargeting_details/sub_location/${sub_location_id}` , {
            params: {
              start_date,
              end_date
            }
          })
           return response;
        } catch (err) {
          console.log("api -error ;",err);
          return err;
        }
       };
   
      
     //** Regenerate Url  */
   export const regenrateUrl = async (customer_Id , bookings_id) => {
    try {
        return await axios.post(
            `${customerServiceURL}/${GetApiStage()}/customer_details/${customer_Id}/bookings/${bookings_id}/regenerate_url`,
        );
    } catch (err) {
        console.log(err);
    }
};

    //** Amber fort get All SubLocations  */
    export const getAmberFortSubLocations = async () => {
      try {
          return await axios.get(
              `${locationServiceURL}/test/locations/f347809d-5700-5bec-fc1f-3b179ba42dd2/sub_location`,
          );
      } catch (err) {
          console.log(err);
      }
    };

    //** Amber Fort get Bookings Url  */
    export const getBookingsAmberFort = async (location_id , sub_location_id) => {
      try {
          return await axios.get(`${locationServiceURL}/test/locations/${location_id}/sub_location/${sub_location_id}/shots_queue`,);
        } catch (err) {
            console.log(err);
        }
    };

      //** Amber Fort Shots  */
      export const getAmberFortShots = async (sub_location_id) => {
        try {
            return await axios.get(`${locationServiceURL}/test/locations/f347809d-5700-5bec-fc1f-3b179ba42dd2/shots`,);
          } catch (err) {
              console.log(err);
          }
      };
       
          //** get Customer Details  */
          export const getCustomerDetailsAmberFort = async (data) => {
            try {
                return await axios.post(`${customerServiceBaseUrl}/test/customer_details`,data);
              } catch (err) {
                  console.log(err);
              }
          };

         //** Create Booking Api  */
         export const createAmberBooking = async (customer_id, formData) => {
          try {
              return await axios.post(`${customerServiceBaseUrl}/test/customer_details/${customer_id}/bookings`, formData);
            } catch (err) {
                console.log(err);
            }
        };