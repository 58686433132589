import { createContext, useContext, useEffect, useRef, useState } from "react"
import { IoIosArrowDown } from "react-icons/io";
import { BiRupee } from "react-icons/bi";
import PaymentCheckoutPage from "./PaymentCheckoutPage";
import { MdDeleteForever } from "react-icons/md";
import DeleteGroupConfirmationModal from "./DeleteGroupConfirmationModal";


const AccordianContext = createContext()

export default function Accordian({ children, key , value, onChange, ...props }) {
    const [selected, setSelected] = useState(value);
    
    useEffect(() => {
        onChange?.(selected)
    }, [selected])

    return (
        <ul {...props} key={props?.id} className="w-full">
            <AccordianContext.Provider  value={{ selected, setSelected }}>
                {children}
            </AccordianContext.Provider>
        </ul>
    )
}


export function AccordianItem({ children,value, trigger, group, handleCheckBoxOnChange , groupEditMode , RefreshBookings, ...props }) {
    const { selected, setSelected } = useContext(AccordianContext);
    const [openGroupPaymentModal ,setOpenGroupPaymentModal]=useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =useState(false);
    const isPaid = group?.booking_details?.some(
        (booking) => booking.transaction_id !== "null"
      );
    const open = selected === value;
    const ref = useRef(null)
    
    
    return (
        <>
        <li key={value} className="border-b bg-white w-[98%] mx-auto" {...props}>
          {(groupEditMode && !isPaid )&& <input
                    type="checkbox"
                    // checked={}
                    onChange={handleCheckBoxOnChange}
                    className="rounded-md"
                /> }

             <header onClick={() => setSelected(open ? null : value)}
                role="button" className={`flex justify-between flex-wrap items-center p-4 font-medium ${open ? 'bg-[#EFECEC] rounded-sm' : ''}`}> 
                  <span className="minMd:w-[23%] mmd:w-[23%]  break-words"> {trigger} </span>
                  {isPaid ? 
                        <>
                          <div className="px-5 py-2.5 w-fit h-fit font-medium text-emerald-600 rounded-lg text-sm flex items-center gap-1">Paid</div>
                        </>
                     :
                        <>
                         <button onClick={(e)=> { e.stopPropagation();
                            setShowDeleteConfirmationModal(true)}}>
                            <MdDeleteForever className="text-4xl text-red-500" />
                          </button>

                        <button onClick={(e)=>{e.stopPropagation();
                            setOpenGroupPaymentModal(true)}}
                            className="px-5 py-2.5 w-fit h-fit font-medium bg-emerald-500 hover:bg-emerald-100 hover:text-emerald-600 text-emerald-50 rounded-lg text-sm flex items-center gap-1">Pay Now
                            <BiRupee />
                        </button>    
                        </>
                     }
                    
                <IoIosArrowDown  size={16} className={`transition-transform ${open ? "rotate-180" : ""}`} />
            </header>
            <div className="overflow-y-hidden transition-all w-full"
                style={{ height: open ? "100%" || 0 : 0 }}
            >
                <div className="px-2 w-full bg-[#EFECEC]" ref={ref}>
                    {children}
                </div>
            </div>
        </li>
        
            {openGroupPaymentModal && <PaymentCheckoutPage modalOpen={openGroupPaymentModal} onCloseModal = {()=>{setOpenGroupPaymentModal(false)}} group={group} groupPaymet={true}  RefreshBookings={RefreshBookings}/>}
            {showDeleteConfirmationModal && <DeleteGroupConfirmationModal  modalOpen={showDeleteConfirmationModal} onCloseModal={()=>{setShowDeleteConfirmationModal(false)}} group={group}  RefreshBookings={RefreshBookings}/> }
        </>
    )
}