// store/store.js
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import dataReducer from "../reducers/dataReducer.js";
import bookingReducer from "../reducers/bookingReducer.js";
import customerReducer from "../reducers/customerReducer.js";
import videoReducer from "../reducers/videoReducer.js";
import transactionReducer from "../reducers/transactionReducer.js";
import dashBoardReducer from "../reducers/dashBoardReducer.js";
import groupVideoReducer from "../reducers/groupvideoReducer.js";
import groupPaymentReducer from "../reducers/groupPaymentReducer.js";

const rootReducer = combineReducers({
  data: dataReducer,
  bookings: bookingReducer,
  customer: customerReducer,
  videos: videoReducer,
  transaction: transactionReducer,
  dashboard: dashBoardReducer,
  groups: groupVideoReducer,
  groupPayment: groupPaymentReducer,
  // Add more reducers if needed
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
