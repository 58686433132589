import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../config/firebase-config';


const Homepage = () => {
    const navigate = useNavigate();
    const bookingsData = useSelector((store) => store?.bookings);
    const [user, loading, error] = useAuthState(auth);
    useEffect(()=>{
       if(!loading && !error && user && user?.emai){
          navigate(`${bookingsData?.location_name}/customer-video`);
       }else{
         navigate(`/login`);
       };
    },[bookingsData?.location_name]);

    // console.log("bookingData : " , bookingsData);
  return (
    <>
       <h1 className='mt-80 bg-red-300'>Home page</h1>
    </>
  )
}

export default Homepage