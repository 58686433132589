import React from 'react'
import Modal from "react-modal";
import CicleLoader from './CicleLoader';
const AreUConfirmModal = ({modalOpen , onCloseModal ,title , Icon ,executeFun ,shareLoading , boughtModal}) => {

    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };

  return (
    <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Payment Modal" style={customStyles} ariaHideApp={false}>

        <div className={` m-0 flex flex-col gap-5 items-start rounded-md  px-8 py-10 text-gray-800 relative mx-auto z-50 md:max-w-md `}>
          {shareLoading && <CicleLoader/> }
            <div className="flex xs:flex-col items-center">
                <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                <i className="bx bx-error text-3xl">
                 {<Icon className="text-2xl" />}
                </i>
                </div>
                <div className=" ml-6 text-left">
                <p className="font-bold">Warning!</p>
                <p className="text-sm text-gray-700 mt-1">{title}
                </p>
                </div>
            </div>
            <div className="text-right mt-4 flex justify-end  w-full">
                <button onClick={()=>{onCloseModal(false)}} id="confirm-delete-btn" className="inline-block w-auto px-4 py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm ml-2 order-2">
                    {boughtModal ? "No" : "Cancel"}
                </button>
                <button onClick={executeFun} id="confirm-cancel-btn" className=" inline-block w-auto px-4 py-2 bg-emerald-200 text-emerald-700 rounded-lg font-semibold text-sm  order-1">
                 {boughtModal ? "Yes" : "Share"}
                </button>
            </div>
            
            
        </div>

      
    </Modal>
  )
}

export default AreUConfirmModal