export const setVideoUrl = (data) => {
	return {
		type: 'SET_VIDEO_URL',
		payload: data,
	};
};

export const closeVideo = () => {
	return {
		type: 'CLOSE_VIDEO',
	};
};
export const setCustomerDetails = (data) => {
	return {
		type: 'SET_CUSTOMER_DETAILS',
		payload: data,
	};
};
