import React, { useState } from "react";
import Modal from "react-modal";
import { mergeGroups } from "../services/api";

const GroupMergeModal = ({ isOpen, onClose, groups }) => {
  const [newGroupName, setNewGroupName] = useState("");
  const handleMergeGroup = async () => {
    try {
      console.log("groups", groups);
      console.log("new group name", newGroupName);
      const data = {
        groupIds: groups,
        newGroupName: newGroupName,
      };
      const response = await mergeGroups(data);
      console.log("respponse for merge grp", response);
      onClose();
    } catch (error) {
      console.log("error while deleting group", error);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="flex items-center justify-center"
      >
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white py-6 px-14 rounded-lg relative">
            <button
              className="absolute top-1 right-1 shadow-md font-bold px-2 text-gray-700 text-lg rounded-lg hover:text-gray-800"
              onClick={onClose}
            >
              &times;
            </button>

            <div className="flex-col items-center text-center">
              <div className=" mx-auto mb-3 ">
                Please enter new group name:{" "}
              </div>
              <input
                type="text"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="New Group Name"
              />
              <div className="flex justify-between my-3">
                <button
                  className="bg-red-600 px-2 py-1 rounded-md shadow-md text-white"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="bg-black px-2 py-1 rounded-md shadow-md text-white"
                  onClick={handleMergeGroup}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GroupMergeModal;
