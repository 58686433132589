import React from 'react';

export default function BillingPage() {
	return (
		<div className='flex h-screen w-screen bg-red-400'>
			<div className='w-[30%] bg-gray-200 p-4'>
				{/* Left Column */}
				<h2 className='text-2xl font-semibold'>Left Column</h2>
				{/* Add content to the left column here if needed */}
			</div>
			<div className='w-[70%] bg-white p-4'>
				{/* Right Column */}
				<h2 className='text-2xl font-semibold'>Right Column</h2>
				<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
					{/* Cards */}
					{Array.from({ length: 6 }).map((_, index) => (
						<div key={index} className='relative border p-4 rounded-lg shadow-md'>
							<h3 className='text-xl font-semibold mb-2'>Card {index + 1}</h3>
							<div>
								<strong>Name:</strong> John Doe
							</div>
							<div className='flex justify-between text-xs'>
								<div className='mb-2 bg-gray-200 border-1 border-gray-500 rounded-md p-2 '>
									<strong>Price:</strong> $50
								</div>
								<div className='mb-2 bg-gray-200 border-1 border-gray-500 rounded-md p-2 '>
									<strong>Number of Persons:</strong> 4
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
