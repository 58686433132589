import React, { useEffect, useState , memo} from 'react'
import './custommodal.css'
import PaymentCheckoutPage from '../PaymentCheckoutPage';
import { Logging, Transactions, createCashfreeOrder, createGroupApi, fetchCouponDetails, getCashfreeOrderDetails } from '../../services/api';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from 'react-responsive';

import Lottie from 'react-lottie';
import confettieJson from '../../assets/jsonFiles/confettie.json'
import qrShowJson from '../../assets/jsonFiles/qrShow.json'
import qrLoadingJson2 from '../../assets/jsonFiles/qrLoader2.json'
import paymentAnimation from "../../assets/payment-done-animation.json";
import { logEvent } from "firebase/analytics";
import { analytics } from '../../config/firebase-config';
import { load } from "@cashfreepayments/cashfree-js";
import { BsCashCoin } from "react-icons/bs";
import { GrOrganization } from "react-icons/gr";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { IoMdArrowDropdown } from "react-icons/io";
import { FaPhotoVideo } from "react-icons/fa";
import { RiCloseCircleFill , RiCoupon2Fill } from "react-icons/ri"
import assets from '../../assets';
import { FaMoneyBillTransfer } from "react-icons/fa6";




const CustomModal = ({modalOpen , onCloseModal ,booking, group ,groupPaymet,RefreshBookings}) => {
   
    const divStyle = { 
        display: modalOpen ? 'block' : 'none'
   };
   
   const [selectPaymentMethod , setSelectPaymentMethod]=useState('upi');

   const [amount, setAmount] = useState(() => {
     if (groupPaymet) {
       // If it's a group payment, calculate the total amount from booking details
       return group?.booking_details.reduce((acc, booking) => acc + parseInt(booking?.amount, 10), 0);
     } else if (Array.isArray(booking)) {
       // If booking is an array, calculate the total amount from multiple bookings
       return booking.reduce((acc, bookingItem) => acc + parseInt(bookingItem.amount, 10), 0);
     } else if (typeof booking === 'object' && booking !== null) {
       // If booking is a single object, extract the amount directly
       return parseInt(booking?.amount, 10) || 0; // Use 0 if amount is not present or invalid
     } else {
       // Handle other cases where booking is not an array or object
       return 0; // Default to 0 if booking is not valid
     }
   });
   
   const [couponList , setCouponList]=useState(null);
   const [paymentProcessing , setProcessing]=useState(false);
   const [formData , setFormData]=useState({phone_number:"" , promo_code:""});
   const [appliedCoupon, setAppliedCoupon] = useState(null);
   const [couponAppliedMessage, setCouponAppliedMessage] = useState("");
   const [discountedAmount, setDiscountedAmount] = useState(0);
   // const [totalAmount, setTotalAmount] = useState(0);
   const [showConfettie , setShowConfettie] = useState(false);
   const [showCashFreeQr , setShowCashFreeQr]=useState(false);
   const [paymentDone , setPaymentDone]=useState(false);
   const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
   const smallScreen = useMediaQuery({ query: '(max-width: 600px)' });
   
   const currentDate = new Date();
   const formattedDate = currentDate?.toLocaleString('en-IN', {
     weekday: 'long', // full day name (e.g., "Monday")
     year: 'numeric', // 4-digit year (e.g., "2023")
     month: 'long', // full month name (e.g., "January")
     day: 'numeric', // day of the month (e.g., "1")
     hour: 'numeric', // hour (e.g., "13" for 1 PM)
     minute: 'numeric', // minute (e.g., "05")
     second: 'numeric', // second (e.g., "30")
     timeZone: 'Asia/Kolkata' // Indian Standard Time (IST)
   });

   useEffect(() => {
       async function fetchCoupon() {
         try {
           // console.log("group", group);
           
           const response = await fetchCouponDetails(groupPaymet ? group?.sub_location_id : Array.isArray(booking) ? booking[0]?.sub_location_id : booking?.sub_location_id);
        //    console.log("response for coupon details", response?.data?.prices);
           const coupons = response.data?.prices;
           const filteredCoupons = coupons.filter(
             (coupon) =>
               coupon.type === "view_shots_website" && coupon.status === "active"
           );
           setCouponList(filteredCoupons);
         } catch (error) {
           toast.error("Error in Coupons !");
           console.log("error in fetchCouponDetails", error);
         }
       }
       fetchCoupon();

     }, []);


   const customStyles = {
       content: {
          zIndex:'20',
          padding:'10px',
          boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
          borderRadius:'12px',
          inset: `${smallScreen ? '10px' : '50px'}`
       },
     };

     const defaultOptions = {
       loop: false,
       autoplay: true, 
       animationData: confettieJson,
       rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice'
       }
     };

     const defaultOptionsQrShow = {
       loop: true,
       autoplay: true, 
       animationData: qrShowJson,
       rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice'
       }
     };


     const defaultOptionsQrLoading = {
       loop: true,
       autoplay: true, 
       animationData: qrLoadingJson2,
       rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice'
       }
     };

     const defaultOptionsPaymentDone={
       loop: false,
       autoplay: true, 
       animationData: paymentAnimation,
       rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice'
       }
     }

     const handleOnChangeForm=(e)=>{
       const name = e.target.name;
       const value = e.target.value;
       if(name === "phone_number"){
           const pattern = /^[0-9]*$/;
           if (pattern?.test(value)) {
               setFormData((prev)=> ({...prev , [name]:value}));
           }   
       }else{
           setFormData((prev)=> ({...prev , [name]:value}));
       }
      
     
     } 

    //   ** Apply Coupon Function  */
     const handleApplyCoupon=()=>{
        if(!formData?.promo_code){
           toast.error('Kindly filled Promo Code');
           return;
        };
        if (appliedCoupon) {
           setAppliedCoupon(null);
           // setTotalAmount(0);
           setDiscountedAmount(0);
           setCouponAppliedMessage("");
           setFormData((prev)=>({...prev , promo_code:""}))
         }else{
           const foundCoupon = couponList.find(
               (coupon) => coupon.coupon_code === formData?.promo_code.toUpperCase()
           );

           if(foundCoupon){
               setAppliedCoupon(foundCoupon);
               setCouponAppliedMessage(`${foundCoupon?.discount} Off`);
               const discountPercentage = parseInt(foundCoupon?.discount);
               const discountAmount = amount * (discountPercentage / 100);
               const finalAmount = amount - discountAmount;
               setDiscountedAmount(discountAmount);
               // setTotalAmount(finalAmount);
               setShowConfettie(true);
                 setTimeout(() => {
                   setShowConfettie(false);
                 }, 2000); // Adjust the delay as needed
           }else{
               setCouponAppliedMessage("Invalid coupon");
               // setTotalAmount(amount); 
               toast.info('Invalid Coupon');
           }
       }  

     }
    
     //  ** Others method Function  */
     const transactionPayment = async(paymentMode , orderId , grpDetals)=>{
       let createTransactionResponse = {};
       let payload = {};
       let paymentInfo='';
       try {
           setProcessing(true);

           if(groupPaymet){
             createTransactionResponse ={
                 group: group,
                 mode: paymentMode,
                 transaction_id: orderId,
                 payment_status: "Completed",
                 amount: amount,
                 discount: appliedCoupon?.discount || "0%",
                 final_amount:paymentMode === "foc" ? "0" : amount  - discountedAmount,
                 sub_location_id: group?.sub_location_id,  
               };
               
               
               payload={
                 a_info: `Payment for Group`,
                 date: new Date(),
                 payment_mod:paymentMode,
                 customer_id:group?.booking_details?.map(item => item?.booking_id),
                 booking_id:group?.booking_details?.map(item => item?.customer_id),
                 group_id:group?.group_id,
                 is_success:true, 
               }

               paymentInfo=`${group?.group_name} : ${group?.group_id} : ${formattedDate} : ${paymentMode === "online" ? "UPI" :paymentMode }`
               
           }else{
             if(Array.isArray(booking)){
               if(selectPaymentMethod === "upi" && grpDetals){
                 grpDetals["booking_details"] = booking;
                 createTransactionResponse = {
                   mode: paymentMode,
                   transaction_id: orderId,
                   payment_status: "Completed",
                   amount: amount,
                   discount: appliedCoupon?.discount || "0%",
                   final_amount: paymentMode === "foc" ? "0" : amount - discountedAmount,
                   sub_location_id: booking[0]?.sub_location_id,
                   group : grpDetals,    
                 };
                 paymentInfo=`${grpDetals.group_name} : ${grpDetals.group_id} :${formattedDate} : ${paymentMode === "online" ? "UPI" :paymentMode }`
               }else{
                 createTransactionResponse = {
                   customers: booking,
                   mode: "internal_offline",
                   transaction_id: orderId,
                   payment_status: "Completed",
                   amount: amount,
                   discount: appliedCoupon?.discount || "0%",
                   final_amount: paymentMode === "foc" ? "0" : amount - discountedAmount,
                   sub_location_id: booking[0]?.sub_location_id,
                 }
                 paymentInfo=`${booking[0].customer_name} : ${booking[0]?.booking_id} :${formattedDate} : ${paymentMode === "online" ? "UPI" :paymentMode }`
               }
               payload={
                 a_info: `Payment for Multiple Bookings `,
                 date: new Date(),
                 payment_mod:paymentMode,
                 customer_id:booking?.map(item => item?.booking_id),
                 booking_id:booking?.map(item => item?.customer_id),
                 is_success:true, 
               }
               
             }else{
               createTransactionResponse = {
                   customers: [booking],
                   mode: paymentMode,
                   transaction_id: orderId,
                   payment_status: "Completed",
                   amount:  amount,
                   discount: appliedCoupon?.discount || "0%",
                   final_amount: paymentMode === "foc" ? "0" : amount - discountedAmount,
                   sub_location_id: booking?.sub_location_id,
                 };

               
                 payload={
                   a_info: `Payment for Multiple Bookings `,
                   date: new Date(),
                   payment_mod:paymentMode,
                   customer_id:[booking?.booking_id],
                   booking_id:[booking?.customer_id],
                   is_success:true, 
                 }
                 paymentInfo=`${booking?.customer_name} : ${booking?.booking_id} :${formattedDate} : ${paymentMode === "online" ? "UPI" :paymentMode }`
               } 
           }

           // ** call Firebase Analytics  */
           if(window.location.hostname !== "localhost"){
             logEvent(analytics, "Payment", {
               payment_info: paymentInfo, });
           };
           
        //    console.log("createTransactionResponse :" , createTransactionResponse);
           const transResponse = await Transactions(createTransactionResponse);
        //    console.log("transResponse:", transResponse);
           setPaymentDone(true);
           
             if(transResponse?.data?.is_success){
               toast.success("Your payment has been processed successfully")
                // ** call Gcplogs  */
               Logging({ payload, severity: 'INFO' });
             }else{
               toast.error("We regret to inform you that your payment was not successful. Please try again");
                 // ** call Gcplogs  */
               payload["is_success"] = false;
               Logging({ payload, severity: 'ERROR' });
             }
         } catch (error) {
           toast.error(error);
           console.log("Error creating cash transaction", error);
           setPaymentErrorMessage("Error creating transaction");
             // ** call Gcplogs  */
           payload["is_success"] = false;
           Logging({ payload, severity: 'ERROR' });
         } finally {
           setProcessing(false);
           setTimeout(() => {
               onCloseModal();
               if (typeof RefreshBookings === 'function') {
                //  console.log("typeof RefreshBookings :" , typeof RefreshBookings);
                 RefreshBookings();
               }
             }, 2200);
         }
     };
     
     //  ** UPI method Function  */
     const paymentUPImehod= async(orderId)=>{
       setProcessing(true);
       let order_details = {};
       let grpDetals = {};
       let payload = {};
       let paymentInfo='';
         if(groupPaymet){
             order_details = {
                 order_amount: amount - discountedAmount,
                 order_id: orderId,
                 group_id: group?.group_id,
                 group_name: group?.group_name,
                 phone_number: formData?.phone_number,
                 order_note: "payment testing",
                 sub_location_id: group?.sub_location_id,
                 amount: amount,
                 discount: appliedCoupon?.discount || "0%",
                 payment_mode: "online",
                 booking_creation_date: group?.created_at,
                 no_of_customers: group?.booking_ids.length,
             }  
             payload={
               a_info: `Payment for Groups Cashfree `,
               date: new Date(),
               payment_mod:"online",
               customer_id:group?.booking_details?.map(item => item?.booking_id),
               booking_id:group?.booking_details?.map(item => item?.customer_id),
               group_id : group?.group_id,
               is_success:true, 
             }
             paymentInfo=`${group?.group_name} : ${group?.group_id} : ${formattedDate} : UPI`
         }else{
         if(Array.isArray(booking)){
            
             order_details = {
               order_amount: amount - discountedAmount,
               order_id: orderId,
               booking_id: booking[0]?.booking_id,
               customer_name: `${booking[0]?.customer_name}-group`,
               phone_number: formData?.phone_number,
               order_note: "payment testing",
               sub_location_id: booking[0]?.sub_location_id,
               amount: amount,
               discount: appliedCoupon?.discount || "0%",
               payment_mode: "online",
               booking_creation_date: booking[0]?.created_at,
               no_of_customers: booking.length,
             }

             const group_details = await createGroup(booking);
             grpDetals = group_details;
             order_details["booking_id"] = group_details.group_id;
             order_details["customer_name"] = group_details.group_name;
             order_details["no_of_customers"] = group_details.booking_ids.length;

             payload={
               a_info: `Payment for Multiple Bookings Cashfree`,
               date: new Date(),
               payment_mod:"online",
               customer_id:booking?.map(item => item?.booking_id),
               booking_id:booking?.map(item => item?.customer_id),
               is_success:true, 
             }
             paymentInfo=`${group_details.group_name} : ${group_details.group_id} :${formattedDate} : UPI`
           }else{
           
             order_details = {
               order_amount: amount - discountedAmount,
               order_id: orderId,
               booking_id: booking?.booking_id,
               customer_name: booking?.customer_name,
               phone_number: formData?.phone_number,
               order_note: "payment testing",
               sub_location_id: booking?.sub_location_id,
               amount: amount,
               discount: appliedCoupon?.discount || "0%",
               payment_mode: "online",
               booking_creation_date: booking?.created_at,
               no_of_customers: 1,
             }

             payload={
               a_info: `Payment for Single Booking Cashfree`,
               date: new Date(),
               payment_mod:"online",
               customer_id:booking?.booking_id,
               booking_id:booking?.customer_id,
               is_success:true, 
             };
             paymentInfo=`${booking?.customer_name} : ${booking?.booking_id} :${formattedDate} : UPI`
           }
         }
        
           
       //  console.log("order_details ===>", order_details);
         const response = await createCashfreeOrder(order_details); 
         const reqBody = JSON.parse(response?.data?.body);
        //  console.log("reqBody : ", reqBody);
         if(reqBody?.payment_session_id){
           const payment_session_id = reqBody?.payment_session_id;
         
           try{
             const cashfree = await load({ mode: "production" });
             const qrComponent = cashfree.create("upiQr", {
               values: {
                 size: "220px",
               },
             });


             qrComponent.mount("#qr");
             setShowCashFreeQr(true);
             qrComponent.on("ready", async function (d) {
                 // setQrLoader(true);
                 // console.log("ready"); //{size: '220px'}
                 const data = await cashfree.pay({
                   paymentMethod: qrComponent,
                   paymentSessionId: payment_session_id,
                   redirectTarget: "_myFrame_",
                 })
                
                //  console.log("cashfree-> data ", data);
                 
                 if (data.error) {
                   console.log("data detail-PAYMENT-error: ", data);
                   toast.error(data?.error);
                   setPaymentErrorMessage(data.error.message);
                    // ** call Firebase Analytics  */
                   if(window.location.hostname !== "localhost"){
                     logEvent(analytics, "CashFree-payment Error", {
                       payment_info: paymentInfo, });
                   };
                   // ** call Gcplogs  */
                   payload["is_success"] = false;
                   Logging({ payload, severity: 'ERROR' });
                 }
                 if (data.paymentDetails) {
                   // console.log("data details: ", data);
                   // console.log("payment details: ", data.paymentDetails);
                 }
                 if (data.redirect) {
                   const response = await getCashfreeOrderDetails(orderId);
                   const body = JSON.parse(response?.data?.body);
                   const order_status = body?.orderDetails?.order_status;
                   if (order_status === "PAID") {
                     // ** call Gcplogs  */
                     Logging({ payload, severity: 'INFO' });
                     transactionPayment("online",orderId , grpDetals);
                   }
                   console.log("Redirecting...---------->");
                 }
                 
               });
           }catch (error) {
             toast.error(error);
             setPaymentErrorMessage(error);
                // ** call Firebase Analytics  */
                if(window.location.hostname !== "localhost"){
                 logEvent(analytics, "CashFree-payment Error", {
                   payment_info: paymentInfo, });
               };
               // ** call Gcplogs  */
               payload["is_success"] = false;
               Logging({ payload, severity: 'ERROR' });
           } finally {
             setProcessing(false);
           };
         }else{
           toast.error("Something Went Wrong !" , reqBody?.error);
           setPaymentErrorMessage("Something Went Wrong !" , reqBody?.error);
              // ** call Firebase Analytics  */
              if(window.location.hostname !== "localhost"){
               logEvent(analytics, "CashFree-payment Error", {
                 payment_info: paymentInfo, });
             };
            // ** call Gcplogs  */
              payload["is_success"] = false;
              Logging({ payload, severity: 'ERROR' });
           setProcessing(false);
         } 
         
     };

     //  ** Proceed Payment Function  */
     const handleProceedPayment=(e)=>{
       e.preventDefault();
       if(selectPaymentMethod === "upi" &&  (!selectPaymentMethod || !formData?.phone_number || formData?.phone_number.trim() === '' || formData?.phone_number.length < 10 || formData?.phone_number.length > 10)){
           if(!selectPaymentMethod){
               toast.error('Kindly Choose Payment Method');
           }else{
               toast.error('Kindly filled phone number');
           }
           
           return ;
       }else{
           const orderId = uuidv4();
          
          switch (selectPaymentMethod) {
           case "upi":
               toast.info("payment proceed in to UPI - Method");
               paymentUPImehod(orderId);
               break;
           case "cash":
               toast.info("payment proceed in to Cash Method");
               transactionPayment("internal_offline",orderId);
               break;

           case "onsite":
               toast.info("payment proceed in to OnSite Method");
               transactionPayment("onsite",orderId);
               break;

            case "foc":
                toast.info("payment proceed in to Free Of Cost Method");
                transactionPayment("foc","11111111-1111-1111-1111-111111111111");
            break;

           case "posMachine":
               toast.info("payment proceed in to PosMachine Method");
               transactionPayment("internal_bank",orderId);
               break;    
          
           default:
               toast.info("Kindly Choose Payment Method");
               break;
          }
       }
     }
     
     //  ** Create Group Api Call Function  */
     async function createGroup(bookings) {
       try {
         let collective_name = bookings
           .map((booking) => booking.customer_name)
           .join("-");
         const bookingIds = bookings.map((booking) => booking.booking_id);
         const groupDetails = {
           groupName: collective_name,
           location_id: bookings[0].location_id,
           sub_location_id: bookings[0].sub_location_id,
           sub_location_name: bookings[0].sub_location_name,
           location_name: bookings[0].location_name,
           bookingIdsArray: bookingIds,
           group_type: "single",
         };
         // console.log("grpdetails", groupDetails);
         const group_details = await createGroupApi(groupDetails);
         // console.log("grpdetails", group_details.data.group);
         return group_details.data.group;
       } catch (error) {}
     }
   
     const confettieSyle = {
           top: '50%',
           position:'absolute',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
           zIndex:'30',
         //   backgroundColor: 'rgba(255, 255, 255, 0.5)',
         //   backdropFilter: 'blur(8px)'
       
     };
     const paymentDoneLottieStyle={
         margin:'auto',
     }

  
    

  return (
    <>
      <div 
         className="modal"
         onClick={ onCloseModal}
         style={divStyle} >
            <div 
             className="modal-content"
             onClick={ e => e.stopPropagation()} >
             {/* Payment Checkout page  */}

                <div className="relative w-full min-h-full flex flex-col">
                    {/* //* Modal Header  */}
                <div className='w-full flex justify-end z-40 relative'><RiCloseCircleFill  className='text-5xl text-red-500 cursor-pointer' onClick={onCloseModal}/></div>
                {/* //* Payment Error Message   */}
                {paymentErrorMessage && <div className=" mt-2 bg-red-100 border border-red-200 text-sm text-red-800 rounded-lg p-4 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500" role="alert">
                    <span className="font-bold">Error</span> {paymentErrorMessage}
                </div>}
                {/* //* Payment Done Lottie  */}
                {paymentDone &&  
                <div className='w-full h-full bg-[#ffffff] bg-opacity-50 backdrop-blur-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30 flex items-center justify-center'>
                        <Lottie options={defaultOptionsPaymentDone}  width="40%" height="auto"  style={paymentDoneLottieStyle}/>
                    </div>
                    }

                    {/* //* Confettie Lottie  */}
                    {showConfettie && <Lottie options={defaultOptions} height="80%" width="100%" style={confettieSyle}/>}
                    {/* //* paymentProcessing Qr Loader Lottie  */}
                    {paymentProcessing &&  <div className='h-[100%] w-full bg-[#ffffff] bg-opacity-50 backdrop-blur-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30 flex items-center mmd:items-start justify-center'>
                        <Lottie options={defaultOptionsQrLoading}  width="50%" height="auto" style={paymentDoneLottieStyle}/>
                    </div>}
                    
                    {/* //* Customers- Details   */}
                
                    {(groupPaymet || Array.isArray(booking)) ? 
                    (booking?.length > 5 ||group?.booking_details?.length > 5) ? 
                        <div className='mb-3 text-center w-full mx-auto'>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<IoMdArrowDropdown />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            
                            >
                            <Typography sx={{fontSize:'24px' , fontWeight:'bold' , color:"black" , fontFamily:'Open Sans' , textAlign:'center' }} ><span className='bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text font-extrabold' > {groupPaymet ? group?.group_name : Array.isArray(booking) ? "Multiple Bookings Payment" : "Single Booking Payment"}</span></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {groupPaymet && 
                                <div className="mt-2 w-[80%] sm:w-[90%]">
                                    <ul className="flex flex-wrap gap-5 w-full items-center justify-between text-lg font-semibold text-gray-500">
                                    { groupPaymet && group?.booking_details.map((booking, index) => (
                                        <li key={index} className="item font-semibold">
                                        {booking?.customer_name}
                                        </li>
                                    ))}
                                    </ul>
                                </div>}
                                {Array.isArray(booking) &&
                                    <div className="mt-2 w-[80%] sm:w-[90%]">
                                        <ul className="flex flex-wrap gap-5 w-full items-center justify-between text-lg font-semibold text-gray-500"> 
                                        {booking.map((booking, index) => (
                                            <li key={index} className="item font-semibold">
                                            {booking?.customer_name}
                                            </li>
                                        ))}
                                        </ul>
                                    </div> }
                            </AccordionDetails>
                        </Accordion>
                        </div>
                        :
                        <div className='mb-3 text-center flex flex-col items-center justify-center'>

                            <h3 className='text-[24px] text-black  bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text font-extrabold'>{groupPaymet ? group?.group_name : Array.isArray(booking) ? "Multiple Bookings Payment" : "Single Booking Payment"}</h3>
                            
                            {groupPaymet && 
                                <div className="mt-2 w-[80%] sm:w-[90%] text-center">
                                <ul className="flex flex-wrap gap-5 w-full items-center justify-center text-lg font-semibold text-gray-500">
                                    { groupPaymet && group?.booking_details.map((booking, index) => (
                                    <li key={index} className="item font-semibold">
                                        {booking?.customer_name}
                                    </li>
                                    ))}
                                    </ul>
                                </div>}

                            {Array.isArray(booking) &&
                                <div className="mt-2 w-[80%] sm:w-[90%] text-center ">
                                    <ul className="flex flex-wrap gap-5 w-full items-center justify-center text-lg font-semibold text-gray-500"> 
                                        {booking.map((booking, index) => (
                                        <li key={index} className="item font-semibold">
                                            {booking?.customer_name}
                                        </li>
                                        ))}
                                    </ul>
                                </div> }
                        </div>
                        : 
                        <div className='flex flex-col justify-center items-center w-full md:w-[80%] xsm:w-[95%] sm:w-full mx-auto'>
                            <h3 className='bg-gradient-to-r text-[24px] from-blue-500 to-green-500 text-transparent bg-clip-text font-extrabold'>Single Booking Payment</h3>
                            <p className='item font-semibold mt-2'>{booking?.customer_name}</p>
                        </div> 
                    } 
                
                

                <div className="flex justify-around gap-5 flex-col w-full mx-auto my-auto">

                    <div>
                        <div className="mx-auto w-full">

                        <div className="mt-0 grid max-w-3xl gap-x-4 gap-y-3 grid-cols-3 sm:grid-cols-2">
                            <div className="relative">
                                <input className="peer hidden" id="radio_1" type="radio" name="radio" value="upi" checked={selectPaymentMethod === "upi"} onChange={(e)=>{setSelectPaymentMethod(e.target.value)}}/>
                                <span className="absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white peer-checked:border-[#ACE2E1]"></span>
                                <label className={`flex h-full cursor-pointer flex-col rounded-lg p-4 shadow-lg shadow-slate-100 peer-checked:bg-[#1AACAC] ${selectPaymentMethod === "upi" ? "bg-[#1AACAC] text-white" : ''} peer-checked:text-white`} htmlFor="radio_1">
                                    <span className="mt-2- font-medium">UPI</span>
                                    <img src={assets?.upi_icon} alt="upi_icon"  className='w-[80%]'/>
                                </label>  
                            </div>

                            <div className="relative">
                            <input className="peer hidden" id="radio_2" type="radio" name="radio" value="cash" checked={selectPaymentMethod === "cash"} onChange={(e)=>{setSelectPaymentMethod(e.target.value)}}/>
                            <span className="absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white ppeer-checked:border-[#ACE2E1]"></span>

                            <label className={`flex h-full cursor-pointer flex-col rounded-lg p-4 shadow-lg shadow-slate-100 ${selectPaymentMethod === "cash" ? "bg-[#1AACAC] text-white" : ''} peer-checked:bg-[#1AACAC] peer-checked:text-white`} htmlFor="radio_2">
                                <BsCashCoin className='text-4xl' />
                                <span className="mt-2 font-medium ">Cash</span>
                            
                            </label>
                            </div>

                            <div className="relative">
                                <input className="peer hidden" id="radio_3" type="radio" name="radio" value="onsite" checked={selectPaymentMethod === "onsite"} onChange={(e)=>{setSelectPaymentMethod(e.target.value)}}/>
                                <span className="absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white peer-checked:border-[#ACE2E1]"></span>
                                <label className={`flex h-full cursor-pointer flex-col rounded-lg p-4 shadow-lg shadow-slate-100 ${selectPaymentMethod === "onsite" ? "bg-[#1AACAC] text-white" : ''} peer-checked:bg-[#1AACAC] peer-checked:text-white`} htmlFor="radio_3">
                                <GrOrganization className='text-4xl'/>
                                    <span className="mt-2 font-medium">On Site</span>
                                
                                </label>
                            </div>

                            <div className="relative">
                              <input className="peer hidden" id="radio_4" type="radio" name="radio" value="foc" checked={selectPaymentMethod === "foc"} onChange={(e)=>{setSelectPaymentMethod(e.target.value)}}/>
                              <span className="absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white peer-checked:border-[#ACE2E1]"></span>

                              <label className="flex h-full cursor-pointer flex-col rounded-lg p-4 shadow-lg shadow-slate-100 peer-checked:bg-[#1AACAC] peer-checked:text-white" htmlFor="radio_4">
                                  {/* <img src={assets?.pos_machine} alt="pos_machine" className='w-8 h-auto object-cover'/> */}
                                  <FaMoneyBillTransfer className='text-4xl' />
                                  <span className="mt-2 font-medium">Free Of Cost</span>
                              </label>
                          </div>

                            {/* <div className="relative">
                                <input className="peer hidden" id="radio_4" type="radio" name="radio" value="posMachine" checked={selectPaymentMethod === "posMachine"} onChange={(e)=>{setSelectPaymentMethod(e.target.value)}}/>
                                <span className="absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white peer-checked:border-[#ACE2E1]"></span>

                                <label className="flex h-full cursor-pointer flex-col rounded-lg p-4 shadow-lg shadow-slate-100 peer-checked:bg-[#1AACAC] peer-checked:text-white" htmlFor="radio_4">
                                    <img src={assets?.pos_machine} alt="pos_machine" className='w-8 h-auto object-cover'/>
                                    <span className="mt-2 font-medium">Pos Machine</span>
                                
                                </label>
                            </div> */}

                        </div>

                        <h1 className="mt-8 relative text-2xl font-medium text-gray-700 sm:text-3xl">Secure Checkout<span className="mt-2 block h-1 w-10 bg-teal-600 sm:w-20"></span></h1>
                        <form action="" className="mt-10 flex flex-col space-y-4 w-[90%] mx-auto" onSubmit={handleProceedPayment}>
                        {selectPaymentMethod === "upi" && <div><label htmlFor="card-name" className="sr-only">Phone Number</label><input type="text" id="phone_number" name="phone_number"  maxLength="10" minLength="10" value={formData?.phone_number} onChange={handleOnChangeForm} placeholder="Phone Numbers" required className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-0 " /></div>}
                        
                        
                        {/* //* Coupon message Component  */}
                            {couponAppliedMessage && 
                            <div className={`bg-red-100 border border-red-200 text-sm text-red-800 ${appliedCoupon ? 'bg-teal-100 border border-teal-200 text-teal-800' : ''} rounded-lg p-4  text-center`} role="alert">
                                    {couponAppliedMessage}
                            </div>}
                            
                            {/* //* Coupon Component  */}
                            {selectPaymentMethod !== "foc" && 
                            <div className="bg-gradient-to-br from-[#AAD7D9] to-[#92C7CF] text-white text-center py-8 px-20 rounded-lg shadow-md relative">
                                {/* <img src={assets?.droame_logo_green} className="w-20 mx-auto mb-4 rounded-lg" alt='img'/> */}
                                {/* <h3 className="text-2xl font-semibold mb-4">20% flat off on all rides within the city<br />using HDFC Credit Card</h3> */}
                             <div className="flex items-center gap-2 sm:flex-col justify-center flex-wrap">
                                    <label htmlFor="card-name" className="sr-only">Promo Code</label>
                                    <input type="text" id="promo_code" name="promo_code" placeholder="Promo Code" value={formData?.promo_code} onChange={handleOnChangeForm} className={`mt-1 block text-indigo-600 rounded border-gray-800 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition border-dotted border-2 focus:ring-0`} /> 
                                    <span id="cpnBtn" className={`border   ${appliedCoupon ? 'bg-red-500 text-white border-red-500' : 'bg-white text-purple-600 border-white'} px-4 py-2 rounded-r cursor-pointer`} onClick={handleApplyCoupon}> {appliedCoupon ? "Remove Code" : "Apply Code"}</span>
                                </div>
                                {/* <p className="text-sm">Valid Till: 20Dec, 2021</p> */}
                                
                                <div className="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 left-0 -ml-6"></div>
                                <div className="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 right-0 -mr-6"></div>

                            </div>}
                        
                            <p className="mt-10 text-center text-sm font-semibold text-gray-500">By making the payment,you agree to the <a href="https://www.droame.com/DeliveryPolicy" target="_blank" rel='noreferrer' className="whitespace-nowrap text-teal-400 underline hover:text-teal-600">Shipping & Delivery Policy</a></p>
                            <button type="submit" className="mt-4 inline-flex w-full items-center justify-center rounded bg-[#1AACAC] py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-teal-500 sm:text-lg">Pay</button>
                        </form>
                        </div>
                    </div>
                    

                    {/* //* Bill Section  */}

                    <div className="relative flex flex-col py-4 px-8 xsm:px-4 rounded-[12px] ">
                    <h2 className="sr-only">Order summary</h2>
                    <div>
                        <img src={assets?.droame_logo_green} alt="droame" className="absolute inset-0 h-full object-cover rounded-[12px] flex justify-center items-center w-full" />
                        <div className="absolute inset-0 h-full w-full bg-gradient-to-b from-[#F5F7F8] to-[#F5F5F5] opacity-95 rounded-[12px]"></div>
                    </div>
                    <div className="relative ">
                        <ul className="space-y-5">
                        <li className="flex justify-between">
                            <div className="inline-flex gap-2">
                            <FaPhotoVideo className='text-5xl border-[1px] p-2 border-droameColor rounded-md'/>
                            <div className="ml-3">
                                <p className="text-base font-semibold text-droameColor">Drone Video</p>
                                <p className="text-sm font-medium text-droameColor text-opacity-80">mp4</p>
                            </div>
                            </div>
                            <p className="text-sm font-semibold text-droameColor">&#8377; {amount}</p>
                        </li>
                        {appliedCoupon && appliedCoupon?.coupon_code && 
                        <li className="flex justify-between">
                            <div className="inline-flex gap-2">
                            <RiCoupon2Fill className='text-5xl border-[1px] p-2'/>
                            <div className="ml-3">
                                <p className="text-base font-semibold text-droameColor">{appliedCoupon?.coupon_code}</p>
                                <p className="text-sm font-medium text-droameColor text-opacity-80">{appliedCoupon?.discount}</p>
                            </div>
                            </div>
                            <p className="text-sm font-semibold text-droameColor">&#8377; -{discountedAmount}</p>
                        </li>}

                        </ul>
                        <div className="my-5 h-0.5 w-full bg-white bg-opacity-30"></div>
                        <div className="space-y-2">
                        <p className="flex justify-between text-lg font-bold text-droameColor"><span>Amount Payble:</span><span>&#8377; {amount- discountedAmount}</span></p>
                        {/* <p className="flex justify-between text-sm font-medium text-white"><span>Vat: 10%</span><span>$55.00</span></p> */}
                        </div>
                    </div>
                    {/* //* Coupon Qr  */}
                    <div className='w-full mt-5  items-center justify-center flex flex-col'>
                        <div id="qr" className='-ml-4'></div>
                        {showCashFreeQr && <div className=" relative mt-5 bg-yellow-100 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-4 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500" role="alert">
                            <span className="font-bold">Hurry!</span> Your QR code will expire in 5 minutes..
                        </div> }
                        
                    </div>
                    
                    {!showCashFreeQr && <div className='w-full h-full border-2 '>
                            <Lottie options={defaultOptionsQrShow}  width="55%" />
                    </div> }
                    

                    {/* <div className="relative mt-10 text-white">
                        <h3 className="mb-5 text-lg font-bold">Support</h3>
                        <p className="text-sm font-semibold">+01 653 235 211 <span className="font-light">(International)</span></p>
                        <p className="mt-1 text-sm font-semibold">support@nanohair.com <span className="font-light">(Email)</span></p>
                        <p className="mt-2 text-xs font-medium">Call us now for payment related issues</p>
                    </div>
                    */}
                    </div>
                </div>                                                                                  
                </div>
           </div>
       
       </div>
    </>
  )
}

export default CustomModal