import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import { BiSolidVideoPlus } from "react-icons/bi";

import ReactHLS from "react-hls";
import { closeVideo } from "../../actions/CustomerAction";
import { useNavigate } from "react-router-dom";
function PortraitVideo({setShowFullPortrait}) {
  let customerData = useSelector((store) => store.customer);
  const bookingsData = useSelector((store) => store.bookings);
  const [status, setStatus] = useState(true);
  const [showInReactPlayer, setShowInReactPlayer] = useState(true);
  let [skip, setSkip] = useState(1);
  let [video_start_time, setVideoStartTime] = useState(0);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const playerRef = useRef(null);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("d")

  const playVideoInHlsPlayer = () => {
    setShowInReactPlayer(!showInReactPlayer);
  };
  const skipIntro = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(video_start_time, "seconds");
      setSkip(0);
    }
  };


  // useEffect(() => {
  //   setSkip(1);
  //   let sub_location_name = customerData.customer_details.sub_location_name;
  //   let start_time = 0;
  //   if (sub_location_name === "Bungee Jumping ") {
  //     console.log("Bungee Jumping");
  //     setVideoStartTime(0);
  //     start_time = 0;
  //   }
  //   if (sub_location_name === "Go Karting") {
  //     console.log("Go Karting");
  //     setVideoStartTime(12); 
  //     start_time = 12;
  //   }
  //   let timeout = setTimeout(() => {
  //     setSkip(0);
  //     console.log("video start time: " + start_time);
  //   }, 1000 * start_time);
  //   return () => clearTimeout(timeout);
  // }, [customerData,customerData.customer_details.sub_location_name  , PlayerReady ,showInReactPlayer]);
   
  // cut video 22 second  
  useEffect(()=>{
    let start_time = 0;
    let sub_location_name = customerData?.customer_details?.sub_location_name;
    if (sub_location_name === "Splash Bungee") {
          start_time = 22;
        }else{
          start_time = 0;
        }

    if(isPlayerReady){
      if (playerRef?.current) {
        playerRef?.current.seekTo(start_time, 'seconds');
      }
    }
   
  },[customerData,isPlayerReady ,showInReactPlayer])
  
  // check player Ready function 
  const handlePlayerReady = () => {
    if(customerData?.customer_details?.location_name === "Splash Bungee"){
      setIsPlayerReady(true);
    }else{
      setIsPlayerReady(false);
    }
  };
 console.log("customerData" , customerData);
//  console.log("bookingData " , bookingsData);

  return (
    <>
   
        <div className="relative flex justify-center items-center h-screen w-screen">
        {customerData.video_url !== null ? 
            <>
                 <div className="absolute -right-[90px] max-w-[90vh] min-h-[60px] z-10 flex justify-between rotate-90 items-center gap-5">
                 {customerData.customer_details !== null && 
                    <span className="text-2xl font-open-sans font-normal">
                        {customerData.customer_details.customer_name.toUpperCase()}
                    </span>} 
                    {customerData.customer_details.hls_uri !== null &&
                    customerData.customer_details.hls_uri !== undefined ? (
                        <button
                        className={` ${showInReactPlayer ? "bg-green-500 text-white border-white " :'bg-blue-500 text-white border-white '}  font-semibold hover:text-white py-2 px-4 border brounded`}
                        onClick={playVideoInHlsPlayer}
                        >
                        {showInReactPlayer ? "BoostPlay" : "NormalPlay"}
                        </button>
                    ) : (
                        ""
                    )}
                    <button
                        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 shadow-md transition duration-300 ease-in-out"
                        onClick={() => {
                            // dispatch(closeVideo());
                            setShowFullPortrait(false);
                        }}
                    >
                        X
                    </button>
                 </div>
                    <div className="transform rotate-90">
                      

                    {showInReactPlayer ? (
                        <ReactPlayer
                        config={{
                                    file: { attributes: { controlsList: "nodownload" } },
                                }}
                                ref={playerRef}
                                onContextMenu={(e) => e.preventDefault()}
                                url={customerData.video_url}
                                className="react-player "
                                controls={true}
                                playing={status}
                                onReady={handlePlayerReady}
                                width="100vh"
                                height="100vw"
                                //   onProgress={handleProgress}
                        />
                    ) : (
                    <div>
                        {customerData?.customer_details?.hls_uri.includes(
                        "master.m3u8"
                        ) ? (
                        <ReactHLS
                        ref={playerRef}
                            width="100vh"
                            height="100vw"
                            url={customerData?.customer_details?.hls_uri}
                            onLoadedData={handlePlayerReady}
                        />
                        ) : (
                        <ReactPlayer
                            config={{
                            file: { attributes: { controlsList: "nodownload" } },
                            }}
                            ref={playerRef}
                            onContextMenu={(e) => e.preventDefault()}
                            url={customerData?.customer_details?.hls_uri}
                            className="react-player"
                            controls
                            playing={status}
                            onReady={handlePlayerReady}
                            width="100vh"
                            height="100vw"
                        
                        />
                        )}
                    </div>
                    )}

                    </div>
            </>       
                :
                <div className="flex justify-center items-center">
                 <BiSolidVideoPlus className="text-8xl"/>
                 <p>No Video</p>
                 <button
                        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 shadow-md transition duration-300 ease-in-out"
                        onClick={() => {
                            // dispatch(closeVideo());
                            setShowFullPortrait(false);
                        }}
                    >
                        X
                    </button>
              </div>}
            </div>
     
    </>
  );
}


export default PortraitVideo;
