// reducers/customerReducer.js
const initialState = {
	loading: false,
	video_url: null,
	customer_details: {},
};

const customerReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_VIDEO_URL':
			return {
				...state,
				video_url: action.payload,
			};
		case 'CLOSE_VIDEO':
			return {
				...state,
				video_url: null,
				customer_details:null,
			};
		case 'SET_CUSTOMER_DETAILS':
			return {
				...state,
				customer_details: action.payload,
			};
		default:
			return state;
	}
};

export default customerReducer;
