import React, { useState } from 'react'
import { toast } from 'react-toastify';
import Modal from "react-modal";
import { MdDeleteForever } from "react-icons/md";
import { removeBookingsFromGroup } from '../services/api';
import LogoLoader from './logoloader/LogoLoader';
const DeleteBookingsConfirmationModal = ({modalOpen , onCloseModal , selectedBookings , group_id , RefreshBookings ,setSelectedBookings , setBookingEditMode}) => {
    const [deleteBookingLoading , setDeleteBookingLoading]=useState(false);
    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };
     

      const handleDeleteGroup = async () => {
        setDeleteBookingLoading(true);
        try {
         const response = await removeBookingsFromGroup(group_id, selectedBookings);
         toast.success(response?.data?.message);
          setSelectedBookings([]);
          setBookingEditMode(false);
          console.log("delete response", response);
          onCloseModal();
          setTimeout(() => {
            RefreshBookings();
        }, 1000);
        } catch (error) {
          console.log("error while deleting group", error);
        }finally{
          setDeleteBookingLoading(false);
        }
      };
      
  return (
   <>
     {deleteBookingLoading ? <LogoLoader/> :
       <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Payment Modal" style={customStyles} ariaHideApp={false}>
                
                    
                <div className="m-0 flex flex-col items-center rounded-md  px-8 py-10 text-gray-800 relative mx-auto">
                    <div className="bg-red-50 rounded-lg">
                    <MdDeleteForever className="text-7xl text-red-500 p-2" />
                    </div>
                    {/* <!-- <img src="" alt=""> --> */}
                    <p className="mt-4 text-center text-xl font-bold">Deleting Bookings</p>
                    <p className="mt-2 text-center text-lg">Are you sure you want to delete the  <span className="truncate font-medium">Bookings </span>?</p>
                    <div className="mt-8 flex justify-center  sm:flex-col gap-3 items-center ">
                        <button onClick={(e)=>{
                            e.preventDefault();
                            handleDeleteGroup();
                        }} className="whitespace-nowrap rounded-md bg-red-500 px-4 py-3 font-medium text-white">Yes, delete the Bookings</button>
                        <button onClick={(e)=> {
                            e.preventDefault();
                            onCloseModal();
                        }} className="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium">Cancel, keep the Bookings</button>
                    </div>
        
        </div>
      </Modal>
    }
   </>
  )
}

export default DeleteBookingsConfirmationModal