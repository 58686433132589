import React from 'react'
import assets from '../../assets'

const SimpleLogoLoader = ({logoWidth}) => {
  return (
   <>
     <img src={assets?.droame_logo_green} alt="droame_logo_green"  className={`logo`} style={{width:logoWidth}}/>
   </>
  )
}

export default SimpleLogoLoader