import React from 'react';
import { Dna } from 'react-loader-spinner';
export default function Loader() {
	return (
		<div className='flex h-screen justify-center items-center bg-slate-100'>
			<div className='p-8 rounded shadow-md'>
				<Dna visible={true} height='80' width='80' ariaLabel='dna-loading' wrapperStyle={{}} wrapperClass='dna-wrapper' />
			</div>
		</div>
	);
}
