// FormComponent.js
import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

const IframeContainer = styled.div`
  position: relative;
  overflow: auto;
  padding-top: 200%; /* 16:9 aspect ratio (height/width) */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const FormComponent = () => {
  const { ids } = useParams();

  const [customerId, bookingId, video, current_status] = ids.split("_");

  const handleShare = async () => {
    console.log("inside handle share");
    console.log(
      `cId: ${customerId}, bId: ${bookingId}, current_status: ${current_status}`
    );
    if (current_status !== "shared") {
      try {
        const response = await fetch(
          `https://umlks472ud.execute-api.us-east-1.amazonaws.com/dev/customer_details/${customerId}/bookings/${bookingId}`,
          {
            method: "PATCH",
            headers: {
              "Access-Control-Request-Method": "PATCH,POST,DELETE,GET,PUT",
              "Access-Control-Request-Headers": "Authorization, Content-Type",
            },
            body: JSON.stringify({
              current_status: "video_shared",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        console.log("Response data:", responseData);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    handleShare();
  }, []);

  return (
    <>
      <IframeContainer>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScCsKeGZc8-w-AELcbfB2wSExDEZOX8KU_byGYy6VMJjVNxbg/viewform?usp=sf_link"
          title="Responsive Google Form"
          allowFullScreen
        >
          Loading…
        </iframe>
      </IframeContainer>
    </>
  );
};

export default FormComponent;
