import { GetBookings, GetGroups } from "../services/api.js";

export const GroupsRequest = () => ({
  type: "FETCH_GROUPS_REQUEST",
});

export const GroupsSuccess = (data) => ({
  type: "FETCH_GROUPS_SUCCESS",
  payload: data,
});

export const GroupsFailure = (error) => ({
  type: "FETCH_GROUPS_FAILURE",
  payload: error,
});

export const setSubLocationDetails = (data) => ({
  type: "SET_SUB_LOCATION_DETAILS",
  payload: data,
});

export const errorInfetchingLocationDetails = (data) => ({
  type: "SET_LOCATION_DETAILS",
  payload: data,
});

export const setCurrentDate = (data) => ({
  type: "SET_DATE",
  payload: data,
});

export const setLocationId = (data) => ({
  type: "SET_LOCATION_ID",
  payload: data,
});

export const fetchGroupsData = (sub_location_id, date) => {
  // console.log(`sub_location_id ${sub_location_id} and date ${date}`);
  return async (dispatch) => {
    try {
      dispatch(GroupsSuccess([]));
      dispatch(GroupsRequest());
      await GetGroups(sub_location_id, date)
        .then((response) => {
          // console.log("response for fetching grps in action", response);
          const groups = response.data.groups;
          if (groups === null) {
            console.log("groups list empty");

            dispatch(GroupsSuccess([]));
          } else {
            dispatch(GroupsSuccess(groups));
          }
        })
        .catch((err) => {
          console.log("error while fetching groups", err.message);
        });
    } catch (err) {
      dispatch(GroupsFailure(err));
    }
  };
};
