// reducers/dataReducer.js
const initialState = {
	loading: false,
	bookings: [],
	error: '',
	discount: '0%',
};

const transactionReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'TRANSACTION_REQUEST':
			return {
				...state,
				loading: true,
			};
		case 'FETCH_TRANSACTION_SUCCESS':
			return {
				...state,
				loading: false,
				error: '',
			};
		case 'FETCH_TRANSACTION_FAILURE':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case 'TRANSACTION_COMPLETED':
			return {
				...state,
				bookings: action.payload,
				loading: false,
			};
		case 'SET_BILLING_NUMBER':
			return {
				...state,
				billing_number: action.payload,
				loading: false,
			};
		case 'APPLY_COUPON':
			return {
				...state,
				discount: action.payload,
			};
		default:
			return state;
	}
};

export default transactionReducer;
