import React, { useState } from 'react'
import Modal from "react-modal";
import CicleLoader from './CicleLoader';
import { RetargettingRemarkApi } from '../services/api';
import { toast } from 'react-toastify';
const CommentModal = ({modalOpen , onCloseModal , customer_id , booking_id , RefreshBookings , booking}) => {
   const [comment , setComment]=useState(booking?.retargeting_remark ? booking?.retargeting_remark : booking?.retargeting?.remark);
    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };
      
      //* Post Retargetting Remarks */
      const handlePostRetargetingRemarks= async ()=>{
        try {
            const response = await RetargettingRemarkApi({customer_id , retargeting_object :{remark:comment}} , booking_id);
            console.log("response : for this " , response);
            if(response?.data?.is_success){
                toast.success(response?.data?.message);
                RefreshBookings();
            }else{
                toast.error("Oops! Something went wrong. Your remark couldn't be sent. Please try again later.");
            }
            onCloseModal();
          } catch (error) {
            console.error("Error fetching feedback questions:", error);
            onCloseModal();
          }
      }
      const shareLoading = false;

  return (
    <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Comment Modal" style={customStyles} ariaHideApp={false}>

        <div className={` m-0 flex flex-col gap-5 items-start rounded-md  px-8 py-10 text-gray-800 relative mx-auto z-50 md:max-w-md `}>
          {shareLoading && <CicleLoader/> }
            <div className="flex xs:flex-col items-center">
                  <p className="text-sm text-gray-700 mt-1">Add your Remark</p>
            </div>

            <div>
                <textarea id="message" name='message' onChange={(e)=> setComment(e.target.value)} value={comment} rows="5" cols="40" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" placeholder="Write your remark here..."></textarea>
            </div>

            <div className="text-right mt-4 flex justify-end  w-full">
                <button onClick={()=>{onCloseModal(false)}} id="confirm-delete-btn" className="inline-block w-auto px-4 py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm ml-2 order-2">
                    Cancel
                </button>
                <button onClick={handlePostRetargetingRemarks} id="confirm-cancel-btn" className=" inline-block w-auto px-4 py-2 bg-emerald-200 text-emerald-700 rounded-lg font-semibold text-sm  order-1">
                 Save
                </button>
            </div>
            
            
        </div>

      
    </Modal>
  )
}

export default CommentModal;