import React, { useState } from 'react'
import Modal from "react-modal";
import { MdDeleteForever } from "react-icons/md";
import { deleteGroup } from '../services/api';
import { toast } from 'react-toastify';
import LogoLoader from './logoloader/LogoLoader';


const DeleteGroupConfirmationModal = ({modalOpen , onCloseModal , group , RefreshBookings}) => {
    const [deleteGroupLoading , setDeleteGroupLoading]=useState(false);
    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };
     

      const handleDeleteGroup = async () => {
        setDeleteGroupLoading(true);
        try {
          const response = await deleteGroup(group?.group_id);
          toast.success(response?.data?.message);
          console.log("delete response", response);
          onCloseModal();
          setTimeout(() => {
            RefreshBookings();
        }, 1000);
        } catch (error) {
          console.log("error while deleting group", error);
        }finally{
          setDeleteGroupLoading(false);
        }
      };

  return (
   <>
     {deleteGroupLoading ? <LogoLoader/> : 
     
      <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Payment Modal" style={customStyles} ariaHideApp={false}>
                  
                      
                  <div className="m-0 flex flex-col items-center rounded-md  px-8 py-10 text-gray-800 relative mx-auto">
                      <div className="bg-red-50 rounded-lg">
                      <MdDeleteForever className="text-7xl text-red-500 p-2" />
                      </div>
                      {/* <!-- <img src="" alt=""> --> */}
                      <p className="mt-4 text-center text-xl font-bold">Deleting Group</p>
                      <p className="mt-2 text-center text-lg">Are you sure you want to delete the  <span className="truncate font-medium">{group?.group_name} </span>?</p>
                      <div className="mt-8 flex justify-center  sm:flex-col gap-3 items-center ">
                          <button onClick={(e)=>{
                              e.preventDefault();
                              handleDeleteGroup();
                          }} className="whitespace-nowrap rounded-md bg-red-500 px-4 py-3 font-medium text-white">Yes, delete the Group</button>
                          <button onClick={(e)=> {
                              e.preventDefault();
                              onCloseModal();
                          }} className="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium">Cancel, keep the Group</button>
                      </div>
          
          </div>
      </Modal> }
   
   </>
  )
}

export default DeleteGroupConfirmationModal