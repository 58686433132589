import React, { useState } from 'react'
import Modal from "react-modal";
import { mergeGroups } from '../services/api';
import { toast } from 'react-toastify';
import LogoLoader from './logoloader/LogoLoader';
const GrpMerModal = ({isOpen , onCloseGrpModal,groups , SetGroupEditMode , RefreshBookings}) => {
    const [grpName ,setGrpName]=useState("");
    const [grpMergeProcessing , setGrpMergeProcessing]=useState(false);
    
    //* Merge Group Function  */}
    const handleMergeGroup=async(e)=>{
       e.preventDefault();
       try {
        const data = {
          groupIds: groups,
          newGroupName: grpName,
        };
        setGrpMergeProcessing(true);
        const response = await mergeGroups(data);
        console.log("respponse for merge grp", response);
        if(response?.data?.message){
            toast.info(response?.data?.message);
        }
          RefreshBookings();
          SetGroupEditMode(false);
          onCloseGrpModal();
        
      } catch (error) {
        console.log("error while deleting group", error);
      }finally{
        setGrpMergeProcessing(false);
      }

    }
   


  return (
  <>
       <Modal
        isOpen={isOpen}
        onRequestClose={onCloseGrpModal}
        contentLabel="GroupMerge Modal"
        className="flex items-center justify-center"
        ariaHideApp={false}
      >
       {grpMergeProcessing && <LogoLoader/>}
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white py-6 px-14 rounded-lg relative w-[40%]">
            <button
              className="absolute top-1 right-1 shadow-md font-bold px-2 text-gray-700 text-lg rounded-lg hover:text-gray-800"
              onClick={onCloseGrpModal}
            >
              &times;
            </button>
             
            <form action="" onSubmit={handleMergeGroup}>
                <div className="mb-5">
                <label htmlFor="name" className="block mb-2 font-bold text-gray-600">Enter Group Name</label>
                <input type="text" id="grpName" name="grpName" placeholder="Groupt Name"   value={grpName} onChange={(e) => setGrpName(e.target.value)}
                className="border border-gray-300 shadow p-3 w-full rounded active:ring-0 focus-within:ring-0 focus:ring-0" required/>
                </div>

                 <button type='submit' className="block w-full bg-webGrenn text-white font-bold p-4 rounded-lg">Submit</button>
            </form>


          </div>
        </div>
      </Modal>
  
  </>
  )
}

export default GrpMerModal;