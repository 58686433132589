import React, { useEffect, useState } from 'react'
import { createAmberBooking, getAmberFortShots, getAmberFortSubLocations, getCustomerDetailsAmberFort } from '../services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SimpleLogoLoader from '../components/logoloader/SimpleLogoLoader';
import AmberFortFormModal from '../components/AmberFortFormModal';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const AmberFortBookingPage = () => {
    
    const bookingsData = useSelector((store) => store.bookings);
    const [subLocationList, setSubLocationList] = useState([]);
    const [selectedSublocation , setSelectedSublocation]=useState();
    const [shots , setShots]=useState([]);
    const [filteredShots , setFilteredShots]=useState([]);
    const [openSublocation , setOpenSublocation]=useState(false);
    const [formData , setFormData]=useState({location_id:bookingsData?.location_id , location_name:bookingsData?.location_name , current_status:"PENDING" , transaction_id:"null" , path_ids:"" , video_orientation:"landscape" , ticket_id:"" , qr_link:"",transaction_details:{mode:"", transaction_id:"null" , payment_status:"Completed" , amount:0 , sub_location_id:"" , discount:0 , final_amount:0,no_of_customers:0 , nationality:""}});
    const [defaultFormData , setDefaultFormData]=useState({location_id:bookingsData?.location_id , location_name:bookingsData?.location_name , current_status:"PENDING" , transaction_id:"null" , path_ids:"" , video_orientation:"landscape" , ticket_id:"" , qr_link:"",transaction_details:{mode:"", transaction_id:"null" , payment_status:"Completed" , amount:0 , sub_location_id:"" , discount:0 , final_amount:0,no_of_customers:0 , nationality:""}});
    const [selectedShot , setSelectedShot]=useState(undefined);
    const [loading , setLoading]=useState(false);
    const [amberQrModal ,setAmberQrModal]=useState(false);
    const [resObj , setResObj]=useState(undefined);
    const [amount , setAmount]=useState(0);

    const paymentModeArr = ["qr" , "cash" , "card" , "foc"];


    const getSubLocations=async()=>{
        try {
            const response = await getAmberFortSubLocations();
            setSubLocationList(response?.data?.sub_locations);
          } catch (error) {
            console.error("Error fetching feedback questions:", error);
          }     
    }

    const getShots=async()=>{
        try {
            const response = await getAmberFortShots();
            console.log("response :", response);
            setShots(response?.data?.shots);
          } catch (error) {
            console.error("Error fetching Shots:", error);
          }     
    }

    useEffect(()=>{
        getSubLocations();
        getShots();
    },[]);

    console.log(subLocationList);
    
    const handleGetShots=async(loc)=>{
        setSelectedSublocation(loc);
        setFormData((prev)=>({...prev , sub_location_id:loc?.sub_location_id , sub_location_name:loc?.sub_location_name}));
        const fil = shots?.filter((val)=>val?.sub_location_id === loc?.sub_location_id);
        setFilteredShots(fil);
        setOpenSublocation(false);

    }

    const handleFormChange=(e , data)=>{
        const name = e.target.name;
        const value = e.target.value;
        if(e.target.name === "shots"){
            if(e.target.checked){
                setSelectedShot(data);
               const shotsObj = {
                   amount:data?.amount,
                   shot_id:data?.shot_id,
                   shot_name:data?.shot_name,
                   shot_mode:data?.shot_mode,
                   path_ids:data?.path_ids,
               }
               const txnObj = {
                    amount:data?.amount,
                    final_amount:data?.amount
               }
               if(formData?.transaction_details?.nationality === "indian"){
                setAmount(Number(data?.price_per_customer?.indian ?? 0) + Number(data?.amount ?? 0));
              }else if(formData?.transaction_details?.nationality === "foreigner"){
                setAmount(Number(data?.price_per_customer?.foreigner ?? 0) + Number(data?.amount ?? 0));
               }else{
                setAmount(data?.amount);
               }
               setFormData((prev)=> ({...prev, ...shotsObj , transaction_details:{...prev?.transaction_details , ...txnObj}}));
            }
        }else if(name === "nationality" && e.target.checked){
            if(e.target.id === "indian" && selectedShot){
                setAmount(Number(selectedShot?.price_per_customer?.indian ?? 0) + Number(selectedShot?.amount ?? 0));
              }else if(e.target.id === "foreigner" && selectedShot){
                setAmount(Number(selectedShot?.price_per_customer?.foreigner ?? 0) + Number(selectedShot?.amount ?? 0));
               } 
            setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , nationality:e.target.id}}));
        }else if(name === "paymentMode"){
            if(e.target.id === "foc"){
                setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , mode:e.target.id , final_amount:0}}));
            }else{
            setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , mode:e.target.id}}));
            }
        }
        else{
            if(name === "no_of_customers"){
                setFormData((prev)=> ({...prev , transaction_details:{...prev?.transaction_details , no_of_customers:value}}));
            }else{
                setFormData((prev)=>({...prev , [name]:value}))
            }
        }

    };
    
    const handlePhoneNumberChange=(number)=>{
        setFormData((prev)=>({...prev ,phone_number:number }))
    }
   
    const createBookingApi=async(c_id)=>{
        try{
            const res = await createAmberBooking(c_id , formData);
            if(res?.data?.is_success){
                setResObj(res?.data?.booking)
                setAmberQrModal(true);
                toast.success(res?.data?.message);
                setFormData(defaultFormData);
                setFilteredShots([]);
                setLoading(false);
            }else{
                toast.info(res?.data?.message);
                setLoading(false);
            }
            console.log("create-bookings : ", res);
        }catch(err){
            toast.error("Something went wrong kindly try after some time");
            setLoading(false);
        }
    }

    const getCustomerDetailsApiCall=async()=>{
        setLoading(true);
      try{
            const res = await getCustomerDetailsAmberFort({customer_name :formData?.customer_name ,phone_number:formData?.phone_number});
            if(res?.data?.is_success){
                await createBookingApi(res?.data?.customer?.customer_id);
            }else{
                toast.info(res?.data?.message);
                setLoading(false);
            }
  
        }catch(err){
            setLoading(false);
        }
    }

    const handleSubmit= async (e)=>{
        e.preventDefault();
       getCustomerDetailsApiCall();
    };

   
    console.log("formData :-->", formData);
    console.log("amount", amount);
 
  return (
    <>
    


    
    <div className='relative flex flex-col justify-center items-center my-5 h-full'>

    {loading && <div className='z-20 w-screen h-full flex items-center justify-center bg-[#ffffffcc] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <SimpleLogoLoader logoWidth="100px"/> </div> }

          <h1 className='my-2 text-2xl bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'>Booking Creating Form</h1>
        <form action="" onSubmit={handleSubmit} className='flex flex-wrap w-[60%] md:w-[70%] mmd:w-[90%] sm:w-full shadow-2xl rounded-xl p-5'>

            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Name</label>
                <div className="relative">
                    <input type="text" id="customer_name" name="customer_name" onChange={handleFormChange} value={formData?.customer_name ? formData?.customer_name : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Name" required/>
                </div>
            </div>

           
            <div className="m-5 w-full">
                <label htmlFor="phone" className="mb-2 block text-sm font-medium">Phone number</label>
                <div className="relative">
                    {/* <input type="text" id="phone_number" name="phone_number" onChange={handleFormChange} value={formData?.phone_number ? formData?.phone_number : ""}  className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-24 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="+91 00000-00000" required />
                    <div className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-500">
                    <label htmlFor="country-code" className="sr-only">Country</label>
                    <select id="country-code" name="country-code" className="block w-full rounded-md border-transparent focus:border-blue-600 focus:ring-blue-600">
                        <option>US</option>
                        <option>CA</option>
                        <option>EU</option>
                    </select> */}
                    {/* </div> */}
                    <PhoneInput
                        className="rounded-full"
                        international
                        placeholder="+91 00000-00000"
                        defaultCountry="IN"
                        value={formData?.phone_number ? formData?.phone_number : ""}
                        onChange={handlePhoneNumberChange}/>
                        
                </div>
            </div>

            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Group Size</label>
                <div className="relative">
                    <input type="text" id="no_of_customers" name="no_of_customers" onChange={handleFormChange} value={formData?.transaction_details?.no_of_customers ? formData?.transaction_details?.no_of_customers : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Group Size" required/>
                </div>
            </div>
          
            <div className='m-5 w-full '>
            <p className="font-medium mb-1 text-gray-500 ">Nationality ?</p>
            <div className="flex sm:flex-col gap-6">
                {[{name:"Indian" , val:"indian"} , {name:"Foreigner" , val:"foreigner"}]?.map((data,idx)=>(
                        <div key={idx} className="w-full relative flex  items-center justify-center rounded-xl bg-gray-50 py-3 font-medium text-gray-700">
                        <input className="peer hidden" type="radio" name="nationality" id={data?.val} onChange={handleFormChange}  checked={formData?.transaction_details?.nationality === data?.val}/>
                        <label className="peer-checked:border-blue-400 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border" htmlFor={data?.val}> </label>
                        <div className="peer-checked:border-transparent peer-checked:bg-blue-400 peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2"></div>
                        <span className="pointer-events-none z-10">{data?.name}</span>
                    </div>
                ))}
            </div>
            </div>
            
            
            
            <div className="w-full m-5">
            <p className="font-medium mb-1 text-gray-500 ">Select Sublocation</p>
                <input className="peer hidden" type="checkbox" name="select-1" id="select-1" value={openSublocation}  onChange={(e)=>setOpenSublocation(e.target.checked)} />
                <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border py-3 px-4 text-center pt-2 text-sm text-gray-700 ring-blue-400 peer-checked:ring">{formData?.sub_location_name ? formData?.sub_location_name : "Select Sublocation" }</label>
         
                <ul className={`${openSublocation ? "max-h-56 py-3 " : "max-h-0 hidden"}select-none flex-col overflow-hidden rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                    {subLocationList?.map((loc,idx)=>(
                         <li onClick={()=>handleGetShots(loc)} key={idx} className={`peer-checked:ring ${formData?.sub_location_name === loc?.sub_location_name ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{loc?.sub_location_name}</li>
                    ))}
                </ul>
            </div>

           {filteredShots?.length > 0 &&  <div className="w-fit space-y-2 m-5">
                <h2 className="text- font-medium text-gray-700">Select your Shots</h2>
                <h1>{amount}</h1>
             <div className='flex gap-4 flex-wrap'>
                {filteredShots?.map((shots , idx)=>(
                    <div key={idx} className="relative flex min-w-40 items-center rounded-lg bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700">
                    <input className="peer hidden" type="radio" name="shots" id={shots?.shot_name} onChange={(e)=>handleFormChange(e,shots)} checked={formData?.shot_id === shots?.shot_id} />
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor={shots?.shot_name}> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">{shots?.shot_name}</span>
                    </div>
                ))}
                
            </div>
            </div>}

            <div className="w-fit space-y-2 m-5">
                <h2 className="text- font-medium text-gray-700">Select your Payment Mode</h2>
             <div className='flex gap-4 flex-wrap'>
                {paymentModeArr?.map((mode,idx)=>(
                    <div key={idx} className="relative flex w-60 items-center rounded bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700">
                    <input className="peer hidden" type="radio" name="paymentMode" id={mode}  onChange={(e)=>handleFormChange(e)} checked={formData?.transaction_details?.mode === mode}/>
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor={mode}> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">{mode?.toUpperCase()}</span>
                </div>
                ))}
                
            </div>
            </div>
            
            <div className='w-full'>
                <button type='submit' className="mt-4 rounded-full bg-blue-800 px-10 py-2 font-semibold text-white">Submit</button>
            </div>
            
        </form>
     
    </div>
    
         <AmberFortFormModal modalOpen={amberQrModal} onCloseModal={()=>setAmberQrModal(false)} resObj={resObj}/>
    </>
  )
}

export default AmberFortBookingPage