import React, { useCallback, useEffect, useState } from 'react'
import Modal from "react-modal";
import { getFeedbackQuestions, submitFeedback } from '../services/api';
import { toast } from 'react-toastify';
import SimpleLogoLoader from './logoloader/SimpleLogoLoader';
import { useSelector } from 'react-redux';
const FeedbackModal = ({modalOpen , onCloseModal , booking , RefreshBookings}) => {
 
    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };

      const [feedbackQuestions , setFeedbackQuestions]=useState(JSON.parse(localStorage.getItem('feedbackApiData')));
      const [selectedOption , setSelectedOption]=useState([]);
      const [loading , setLoading]=useState(false);
      const bookingsData = useSelector((store) => store.bookings);
      
       //* Feedback GET Api Call */
      const fetchFeedbackQuestions = async () => {
        setLoading(true);
          try {
            // Call the getFeedbackQuestions function
            const response = await getFeedbackQuestions();
            setFeedbackQuestions(response?.data?.data?.question);
            localStorage.setItem('feedbackApiData',JSON.stringify(response?.data?.data?.question));
            console.log("Feedback questions-api:", response); // Assuming response contains data attribute
            setLoading(false);
          } catch (error) {
            console.error("Error fetching feedback questions:", error);
            setLoading(false);
          }
        };

      useEffect(()=>{
           const localStorageFeedbackVal = localStorage.getItem('feedbackApiData');
            if (localStorageFeedbackVal) {
              setFeedbackQuestions(JSON.parse(localStorageFeedbackVal));
            } else {
              fetchFeedbackQuestions(); 
            }
      },[]);

        
      //* Feedback POST Api Call */
      const handleSubmitFeedback=async()=>{
         if(selectedOption?.length < 1){
          return toast.info("Kindly Choose Feedback !");
         };

         for(let i =0; i<selectedOption?.length; i++){
          try {
              const response = await submitFeedback({question_id:selectedOption[i]?.q_id , response_key:selectedOption[i]?.o_id, mode:'view-shot-webisite',customer_id:booking?.customer_id,booking_id:booking?.booking_id , sub_location_id:booking?.sub_location_id});
              toast.success(response?.data?.data?.message);
            } catch (error) {
              console.error("Error fetching feedback questions:", error);
              toast.error("Something Went Wrong Kindly Check After Some time ");
            }
          };
          onCloseModal();
          setSelectedOption([]);

      };
       
       //* Feedback Add */
       const handleOnChange=(e, q_id , key)=>{
        const isChecked = e.target.checked;
        const updatedOption = { q_id, o_id: key };
        setSelectedOption(prevSelectedOption=>{
           if(isChecked){
            return [...prevSelectedOption?.filter(val => val?.q_id!==q_id) , updatedOption];
           }else{
             return prevSelectedOption?.filter(val=>val?.q_id !== q_id);
           }
        });
           
       };

 

  return (
      <>
        <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Feedback Modal" style={customStyles} ariaHideApp={false}>
                
                    
                <div className={`m-0 flex flex-col gap-5 items-start rounded-md  px-8 py-10 text-gray-800 relative mx-auto ${feedbackQuestions ? "min-w-[455px] min-h-[284px]":""} `}>
                    {loading ? <div className='w-full h-full flex items-center justify-center'> <SimpleLogoLoader logoWidth="80px"/> </div> : <>
                    <div className="rounded-lg">
                    <p className="text-xl font-black text-center">Video Feedback</p>
                    {feedbackQuestions?.length > 0 ? 
                      feedbackQuestions?.map((q , idx)=>(
                        <div key={q?.question_id} className=" rounded-lg">
                           
                            <p className="mt-3">{idx+1}. {q?.question}</p>
                        
                            <div className="mt-3 flex flex-wrap justify-between space-y-1">
                            {Object.keys(q?.options).length > 0 &&  Object.entries(q?.options).map(([key, value])=>(
                                   <div key={key} className="mb-[0.125rem] me-4 inline-block min-h-[1.5rem] ps-[1.5rem]">
                                    <input
                                        className="relative float-left -ms-[1.5rem] me-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-secondary-500 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-checkbox before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ms-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60 focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-checkbox checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ms-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent rtl:float-right dark:border-neutral-400 dark:checked:border-primary dark:checked:bg-primary"
                                        type="checkbox"
                                        name='optionsCheckbox'
                                        onChange={(e)=> handleOnChange(e, q?.question_id , key)}
                                        id={key?.toString() + q?.question_id}
                                        value={key}
                                        // checked={selectedOption?.o_id  === key?.toString() + q?.question_id ? true : false}
                                        checked={selectedOption?.some(obj =>( obj?.q_id === q?.question_id && obj?.o_id === key) )}
                                         />
                                    <label
                                        className="inline-block ps-[0.15rem] hover:cursor-pointer"
                                        name='optionsCheckbox'
                                        htmlFor="inlineCheckbox1"
                                        >{value}</label>
                                 </div>
                            ))}
                              </div>
                        </div>
                      ))
                       
                      :
                      <div><p>Currently Not Have Any Feedback !</p></div> 
                    }
                    </div>
                    
                    {feedbackQuestions &&
                    <div className="mt-8 flex justify-between w-full  sm:flex-col gap-3 items-center ">
                        <button onClick={(e)=>{
                            e.preventDefault();
                            handleSubmitFeedback();
                        }} className="whitespace-nowrap rounded-md bg-emerald-500 px-4 py-3 font-medium text-white">Submit</button>
                        <button onClick={(e)=> {
                            e.preventDefault();
                            onCloseModal();
                        }} className="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium">Cancel</button>
                    </div> }
                </>}
        
        </div>
      </Modal>
      </>
  )
}

export default FeedbackModal