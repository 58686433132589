// reducers/dataReducer.js
const initialState = {
	loading: false,
	items: [],
	error: '',
	name: 'initial',
};

const dataReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_DATA_REQUEST':
			return {
				...state,
				loading: true,
				name: 'rohit',
			};
		case 'FETCH_DATA_SUCCESS':
			return {
				loading: false,
				items: action.payload,
				error: '',
				name: 'ankit',
			};
		case 'FETCH_DATA_FAILURE':
			return {
				loading: false,
				items: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default dataReducer;
