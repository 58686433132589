import React, { useEffect } from "react";
import { Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../config/firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        const userToken = localStorage.getItem('user_token');
    
        // if (!userToken || userToken === 'undefined') {
        //     navigate("/login")
        // }
        if(!user || !user?.email){
            navigate("/login")
        }
    }, [location , user , loading]);

    

    return <>{children}</>;
};

export default ProtectedRoute;
