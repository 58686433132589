const initialState = {
	loading: false,
	video_url: null,
	groupDetail: {},
};

const groupReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_GROUP_VIDEO_URL':
			return {
				...state,
				video_url: action.payload,
			};
		case 'CLOSE_GROUP_VIDEO':
			return {
				...state,
				video_url: null,
			};
		case 'SET_GROUP_DETAILS':
			return {
				...state,
				groupDetail: action.payload,
			};
		default:
			return state;
	}
};

export default groupReducer;
