// reducers/bookingReducer.js
const TodayDate = () => {
	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1 to get the actual month number
	const day = date.getDate();

	const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
	return formattedDate;
};

const initialState = {
	loading: false,
	bookings: [],
	error: '',
	location_id: '',
	location_name: '',
	sub_location_name: '',
	video_uploaded:[],
	video_shot_completed:[],
	date: TodayDate(),
	searchResults: {},
};

const dashBoardReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_DASHBOARD_REQUEST':
			return {
				...state,
				loading: true,
				bookings: [],
				video_pending: [],
				video_shared: [],
				video_uploaded: [],
				video_shot_completed:[],
			};
		case 'FETCH_DASHBOARD_SUCCESS':
			return {
				...state,
				loading: false,
				bookings: action.payload,
			};
		case 'FETCH_DASHBOARD_FAILURE':
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case 'SET_DASH_DATE':
			return {
				...state,
				date: action.payload,
			};
		case 'SET_VIDEO_SHARED':
			return {
				...state,
				video_shared: action.payload,
			};
		case 'SET_VIDEO_UPLOADED':
			return {
				...state,
				video_uploaded: action.payload,
			};
		case 'SET_VIDEO_PENDING':
			return {
				...state,
				video_pending: action.payload,
			};
			case 'SET_VIDEO_SHOT_COMPLETED':
				return {
					...state,
					video_shot_completed: action.payload,
				};
		default:
			return state;
	}
};

export default dashBoardReducer;
