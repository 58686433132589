// reducers/bookingReducer.js
const TodayDate = () => {
	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1 to get the actual month number
	const day = date.getDate();

	const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
	return formattedDate;
};

const initialState = {
	loading: false,
	bookings: [],
	error: '',
	location_id: '',
	location_name: '',
	sub_location_name: '',
	sub_location_details : {},
	sub_location_activity_type:'',
	date: TodayDate(),
	searchResults: {},
};

const bookingReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_BOOKING_REQUEST':
			return {
				...state,
				loading: true,
			};
		case 'FETCH_BOOKING_SUCCESS':
			return {
				...state,
				loading: false,
				bookings: action.payload,
			};
		case 'FETCH_BOOKING_FAILURE':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case 'SET_LOCATION_DETAILS':
			return {
				...state,
			};
		case 'SET_DATE':
			return {
				...state,
				date: action.payload,
			};
		case 'SET_LOCATION_ID':
			return {
				...state,
				location_id: action.payload,
			};
		
		case 'SET_LOCATION_NAME':
			return {
				...state,
				location_name : action.payload,
			}
			case 'SET_SUB_LOCATION_NAME':
				return {
					...state,
					sub_location_name : action.payload,
				}
		case 'SET_SUB_LOCATION_DETAILS':
			return {
				...state,
				sub_location_details: action.payload,
			};
		case 'SET_SUB_LOCATION_ACTIVITY_TYPE':
			return {
				...state,
				sub_location_activity_type: action.payload,
			};
		default:
			return state;
	}
};

export default bookingReducer;
