import droame_logo_green from './droame_logo.png';
import localized_autonomy_drone from './localized-autonomy.png';
import upi_icon from './upi_icon.svg';
import pos_machine from './posMachine.png';
import online_payment from './online-payment.png';
import notFonund_drone from './notFound-drone.jpg'

export default {
    droame_logo_green,
    localized_autonomy_drone,
    upi_icon,
    pos_machine,
    online_payment,
    notFonund_drone,
}