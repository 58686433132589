import React, { memo, useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { PiLinkBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails, setVideoUrl } from '../actions/CustomerAction';
import { IoMdShareAlt } from "react-icons/io";
import { BiRupee } from "react-icons/bi";
import { Logging, RetargettingRemarkApi, Transactions, UpdateBookingDetails, regenrateUrl } from "../services/api";
import { toast } from 'react-toastify';
import { uuidv4 } from "@firebase/util";
import { QRCode } from 'react-qrcode-logo';
import { MdQrCodeScanner } from "react-icons/md";
import PaymentCheckoutPage from './PaymentCheckoutPage';
import { IoMdArrowDropdown } from "react-icons/io";
import assets from '../assets';
import { IoCloseSharp } from "react-icons/io5";
import FeedbackModal from './FeedbackModal';
import AreUConfirmModal from './AreUConfirmModal';
import { FaRegShareSquare } from "react-icons/fa";
import { logEvent } from "firebase/analytics";
import { analytics } from '../config/firebase-config';
import { BiSolidComment } from "react-icons/bi";
import CommentModal from './CommentModal';
import CustomModal from './Custommodal/CustomModal';
import { useMediaQuery } from 'react-responsive';
import { FaCopy } from "react-icons/fa6";
import { MdVideoLibrary } from "react-icons/md";

const TableRowComp = ({phone_number,customer_name ,customer_id, current_status , booking_id , booking  , isExpanded , transaction_id , location_name ,setIsExpanded , bookingEditMode , setSelectedBookings,user , isBookingSelected , g_id , multipleBookingPayment , RefreshBookings , selectedSubLocation , short_video_uri}) => {

    const dispatch = useDispatch();
    let customerData = useSelector((store) => store.customer);
    const [shareQR, setShareQR] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [modalOpen , setModalOpen]=useState(false);
    const [feedbackModalOpen , setFeedbackModalOpen]=useState(false);
    const [confirmShareModalOpen ,setConfirmShareModalOpen]=useState(false);
    const [shareLoading , setShareLoading ]=useState(false);
    const [commentModalOpen , setCommentModalOpen]=useState(false);
    const currentDate = new Date();
    const formattedDate = currentDate?.toLocaleString('en-IN', {
      weekday: 'long', // full day name (e.g., "Monday")
      year: 'numeric', // 4-digit year (e.g., "2023")
      month: 'long', // full month name (e.g., "January")
      day: 'numeric', // day of the month (e.g., "1")
      hour: 'numeric', // hour (e.g., "13" for 1 PM)
      minute: 'numeric', // minute (e.g., "05")
      second: 'numeric', // second (e.g., "30")
      timeZone: 'Asia/Kolkata' // Indian Standard Time (IST)
    });
    const [openBoughtModal , setOpenBoughtModal]=useState(false);
    const mobileScreen = useMediaQuery({ query: '(max-width: 750px)' })

    //* Video Share Function*/

      const handleShare = async () => {
        if(window.location.hostname !== "localhost"){
          logEvent(analytics, "video_shared", {
            customer: `${booking?.customer_name} : ${
              booking?.booking_id
            } : ${formattedDate}`,
            sub_location_id: `${booking?.sub_location_id}`,
          });
        };

        setShareLoading(true);
        let payload = {
          a_info: `video is shared through website`,
          date: new Date(),
          customer_id:customer_id,
          booking_id:booking_id,
          is_success:true, 
        };

          try {

            await UpdateBookingDetails(customer_id, booking_id, null).catch(
              (error) => {
                toast.error("Something Went Wrong !");
              });

            const transactionData = {
              customers: [{ ...booking, billed_amount: 800 }],
              mode: "onsite",
              transaction_id: uuidv4(),
              payment_status: "Completed",
            };
        
            const res = await Transactions(transactionData);
             if(res?.data?.is_success){
              toast.success(res?.data?.message);
              setConfirmShareModalOpen(false);
              setShowQR(true);
             }else{
              toast.error(res?.data?.message);
             }
               // ** call Gcplogs  */
              Logging({ payload, severity: 'INFO' });
          } catch (error) {
            console.error("Handle Share Error:", error)
            toast.error("Something Went Wrong !");
             // ** call Gcplogs  */
            payload["is_success"] = false;
            Logging({ payload, severity: 'ERROR' });
          } finally {
            setShareLoading(false);
          }
      };

      const handleStatus=(status)=>{
         if(status === "PENDING"){
            return  "Not Captured";
         }else if(status === "video_shared"){
             if(location_name === "The Thrill Factory"){
                return "Uploaded";
             }else{
              return "Shared";
             }
         }else if(status === "video_uploaded"){
            return "Uploaded";
         }else if(status === "deleted"){
            return "Not Captured";
         }else if(status === "shot_completed"){
          return "In process";
         }
      };

      const toggleClick=()=>{
        if (isExpanded === booking_id) {
          // If the same tab is clicked again, close it
          setIsExpanded(false);
        } else {
          // Open the clicked tab and close the currently open tab
          setIsExpanded(booking_id);
        }
      }
      
      //  * Bookings Select Function*/
      const handleBookingSelect=(e)=>{
           if(e.target.checked){
            if(g_id){
              setSelectedBookings((prev)=> ([...prev , { booking_id, customer_name , created_at:booking?.created_at }]))
            }else{
              setSelectedBookings((prev)=> ([...prev , booking]));
            }
           }else{
            if(g_id){
              setSelectedBookings((prev)=> prev.filter((bk)=> bk.booking_id !== booking_id));
            }else{
              setSelectedBookings((prev)=> prev?.filter((b)=>b?.booking_id !== booking_id));
            }
           }
      };

       //  * check videou url Function*/
       const checkVideoUrl = ()=>{
         if(booking?.video_uri_wasabi){
           return booking?.video_uri_wasabi;
         }else if(booking?.video_uri_s3){
            return  booking?.video_uri_s3
         }else if( booking?.video_uri){
            return  booking?.video_uri;
         }else{
          return false;
         }
       }
       
        //  * Play Video Function  */
       const handlePlayVideo=(e)=>{
        e.stopPropagation();
        dispatch(setVideoUrl(booking?.video_uri_wasabi ? booking?.video_uri_wasabi : booking?.video_uri_s3 ? booking?.video_uri_s3 :  booking?.video_uri ));
        dispatch(setCustomerDetails(booking));
          if(window.location.hostname !== "localhost"){
            logEvent(analytics, "video_viewed", {
              customer: `${booking?.customer_name} : ${
                booking?.booking_id
              } : ${formattedDate}`,
              sub_location_id: `${booking?.sub_location_id}`,
            });
          };

          let payload = {
            a_info: `video is Played in the website`,
            date: new Date(),
            customer_id:customer_id,
            booking_id:booking_id,
            is_success:true, 
          };

        // ** call Gcplogs  */
        Logging({ payload, severity: 'INFO' });
       }

        //  * Share QR (share video)  */
        const handleShareQr=(e)=>{
          e.preventDefault();
          setShareQR((prev)=>!prev);
          if(window.location.hostname !== "localhost"){
            logEvent(analytics, "qr_shared", {
              customer: `${booking?.customer_name} : ${
                booking?.booking_id
              } : ${formattedDate}`,
              sub_location_id: `${booking?.sub_location_id}`,
            });
            };

            let payload = {
              a_info: `Share QR (for video shared)`,
              date: new Date(),
              customer_id:customer_id,
              booking_id:booking_id,
              is_success:true, 
            };
            // ** call Gcplogs  */
            Logging({ payload, severity: 'INFO' });
        }
        
      //  * Share QR (share video)  */
      const handleRegenrateUrl=async(e)=>{
        e.stopPropagation();
        try {
            const response = await regenrateUrl(customer_id, booking_id);
            if(response?.data){
              toast.success("Success! Your URL has been regenerated successfully.");
            }
            RefreshBookings();
         } catch (err) {
            toast.error("Oops! Something went wrong. Please try again later.");
            console.log("err : ", err);
        }
      }

      const handleCopyClick = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(short_video_uri ? short_video_uri : booking?.video_uri_wasabi ? booking?.video_uri_wasabi : booking?.video_uri_s3 ? booking?.video_uri_s3 :  booking?.video_uri)
          .then(() => {
            toast.success("Video URL Copied!");
          })
          .catch(err => {
            toast.error('Failed to copy video url: ', err);
          });
      };

      
       //* Post Retargetting Remarks */
       const handlePostRetargetingRemarks= async ()=>{
        try {
            const response = await RetargettingRemarkApi({customer_id , retargeting_object :{bought:true}} , booking_id);
            if(response?.data?.is_success){
                toast.success(response?.data?.message);
                RefreshBookings();
            }else{
                toast.error("Oops! Something went wrong. Your remark couldn't be sent. Please try again later.");
            }
            setOpenBoughtModal(false);
          } catch (error) {
            setOpenBoughtModal(false);
            toast.error("Oops! Something went wrong. Your remark couldn't be sent. Please try again later.");
            console.error("Error fetching feedback questions:", error);
           
          }
      }
  

    
  return (
   <>
      

      <tr onClick={toggleClick}  className={`hover:bg-gray-100  whitespace-no-wrap ${isExpanded === booking?.booking_id ? 'border-t-[1px] ' :''} ${booking_id === customerData?.customer_details?.booking_id  ? 'row_shadow bg-gray-200 rounded-lg':''}`} key={booking_id} >
                
                <td  className={`whitespace-no-wrap py-4 text-left text-sm text-gray-600  minMd:w-[23%] mmd:w-[23%] ${!g_id ? 'px-3' :''}`}>
                {g_id && (bookingEditMode === g_id) && (transaction_id === "null" || !transaction_id ) && ( <span>
                          <input
                            type="checkbox"
                            checked={isBookingSelected}
                            onChange={handleBookingSelect}
                            className="rounded-md mr-2"
                          />
                  </span>)}
                  {multipleBookingPayment && (transaction_id === "null" || !transaction_id ) && (selectedSubLocation?.payment_type === "post_paid" ? current_status === "video_uploaded" : true) &&
                   (<span>
                          <input
                            type="checkbox"
                            checked={isBookingSelected}
                            onChange={handleBookingSelect}
                            className="rounded-md mr-2"
                          />
                  </span>)}
                  <span className='xsm:hidden minMd:break-all'>{customer_name?.toUpperCase()}</span>
                  <div className="mt-1 flex-row gap-2 text-xs font-medium hidden xsm:flex gap-y-3">
                    <div className="flex flex-col gap-2 text-gray-600 font-bold"> 
                    {customer_name} <br />
                    {user?.email === "retargeting_thrill@droame.com" && <span className='whitespace-nowrap'>{phone_number}</span> }
                    </div>

                    <div className="flex ">
                    <span className={`rounded-full px-3 py-1.5 text-center bg-green-100  text-green-800 ${current_status === "video_uploaded" ? 'bg-teal-100 text-teal-800' : current_status === "video_shared" ? 'bg-emerald-200 text-emerald-800' : current_status === "deleted" || current_status === "PENDING" ? 'bg-red-100 text-red-800' : ''}`}>{
                      handleStatus(current_status)}</span>
                   
                    </div>
                    
                    <div className="flex items-center xsm:items-end" onClick={toggleClick}>
                    {user?.email === "retargeting_thrill@droame.com" && 
                    <div className='flex gap-2'>
                      <BiSolidComment onClick={(e)=> {
                      e.stopPropagation();
                      setCommentModalOpen(true)}} className='text-lg'/>
                       {/* <PiLinkBold className='text-lg' onClick={handleRegenrateUrl}/> */}

                      </div>}
                     </div>
                
                  </div>
                </td>


                <td className="xsm:hidden whitespace-no-wrap py-4 text-sm font-normal text-gray-500 sm:px-3  text-left minMd:w-[32%]">
                    <span className={`whitespace-nowrap rounded-full bg-green-100 px-3 py-1.5 text-green-800 ${current_status === "video_uploaded" ? 'bg-teal-100 text-teal-800' : current_status === "video_shared" ? 'bg-emerald-200 text-emerald-800' : current_status === "deleted" || current_status === "PENDING" ? 'bg-red-100 text-red-800' : ''}`}>{handleStatus(current_status)}</span>
                </td>
                 
                {user?.email === "retargeting_thrill@droame.com" && <td className='xsm:hidden whitespace-no-wrap py-4 text-sm font-normal text-gray-500 sm:px-3  text-left minMd:w-[32%]'><span>{phone_number}</span></td>}


                <td className="whitespace-no-wrap py-4 text-sm text-left font-normal text-gray-500 sm:px-3 lg:table-cell1 flex gap-2 items-center  xsm:justify-end minMd:w-[52%]">
                 
                 {current_status === "deleted" ?  
                   <div onClick={(e)=> e.stopPropagation()} className='px-3 py-1.5  flex items-center justify-center font-medium text-white rounded-lg text-sm opacity-50 filter  bg-gradient-to-br from-gray-600 to-red-500'>Unavailabel</div>
                   :   
                   checkVideoUrl() ? 
                   <>
                   {/* {user?.email === "retargeting_thrill@droame.com" && <span className='mr-2'> <FaCopy className='text-lg cursor-pointer' onClick={handleCopyClick}/> </span> } */}
                  
                  <button  onClick={handlePlayVideo} 
                    className={`px-3 py-1.5 flex items-center justify-center font-medium text-white rounded-lg text-sm opacity-50 filter  bg-gradient-to-br ${ booking_id === customerData?.customer_details?.booking_id  ? current_status === 'deleted' ? 'from-red-600 to-red-500' : 'from-emerald-600 to-green-500':'from-purple-600 to-blue-500'}`}>
                    {booking_id === customerData?.customer_details?.booking_id  ? current_status === 'deleted' ? 'Not Play' :'Play Video' : 'View Video'}
                  </button> 
                 
                    {user?.email === "retargeting_thrill@droame.com" && <span><PiLinkBold onClick={handleRegenrateUrl}  className='text-xl cursor-pointer mr-1'/></span>}
                     </>
                 :
                  <div onClick={(e) => e.stopPropagation()} className='px-3 py-1.5 flex items-center justify-center font-medium text-white rounded-lg text-sm opacity-50 filter  bg-gradient-to-br from-gray-600 to-black'>Not Play</div>
                 }
                </td>

                
                <td className={`xsm:hidden whitespace-no-wrap cursor-pointer transform transition-transform duration-300 ease-in-out minMd:w-[3%] px-3  ${!g_id ? 'px-3' :''}`}>
                {user?.email === "retargeting_thrill@droame.com" && 
                  
                    <BiSolidComment onClick={(e)=> {
                    e.stopPropagation()
                    setCommentModalOpen(true)}} className='text-xl'/>
                    }
                   <IoMdArrowDropdown className={`text-lg transform transition-transform duration-300 ease-in-out  ${isExpanded === booking?.booking_id ? 'rotate-180' : 'rotate-0'}`} />
                </td>
                
      </tr>
          
          {/* //*  tr - collapse Tab */}

            {isExpanded === booking_id && (
                <tr  className={`${isExpanded === booking_id ? 'relative border-b-[1px] shadow-sm pb-2 h-20 ' :'h-0'} w-full transition-all duration-300 ease-in-out`} key={booking_id + 1}>
                   <td colSpan="5">
                    <div className='flex flex-wrap gap-5 mt-2 mb-2 px-3 '>

                     {/* //* Share Button*/}
                    {(transaction_id !== null && transaction_id !== "null") && (current_status === "video_shared" || current_status === "video_uploaded") &&
                        <button onClick={(e)=>{
                              e.preventDefault();
                              // handleShare();
                              setConfirmShareModalOpen(true);
                            }} className="px-5 py-2.5 w-fit h-fit font-medium bg-blue-50 hover:bg-blue-100 hover:text-blue-600 text-blue-500 rounded-lg text-sm flex items-center gap-1">Share
                                <IoMdShareAlt />
                            </button>}
                    
                     {/* //* Pay Now Button   */}
                    {(selectedSubLocation?.payment_type === "post_paid" ? current_status === "video_uploaded" : true) && (transaction_id === null || transaction_id === "null" ) && 
                       <>
                        <button onClick={()=>{setModalOpen(true)}} className="px-4 py-2.5 xs:px-2 xs:py-1.5 w-fit h-fit font-medium bg-emerald-50 hover:bg-emerald-100 hover:text-emerald-600 text-emerald-500 rounded-lg text-sm xs:text-xs flex items-center gap-1">Pay Now
                            <BiRupee />
                        </button>
                       
                           {/* //* Feedback Button   */}
                            <button onClick={(e)=> {e.preventDefault()
                            setFeedbackModalOpen(true);
                            }} className="inline-flex items-center justify-center px-2 py-1.5 xs:px-2 xs:py-1.5 text-sm xs:text-xs font-medium leading-6 text-gray-600 whitespace-no-wrap bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:shadow-none">
                              Feedback
                            </button>
                       </>   
                     }
                    
                     {/* //* Paid Now Button   */}
                     {(transaction_id && transaction_id !== "null") && 
                        <p className="px-5 py-2.5 xs:px-2 xs:py-1.5 w-fit h-fit font-medium bg-emerald-500 hover:bg-emerald-600 hover:text-emerald-100 text-emerald-50 rounded-lg text-sm xs:text-xs flex items-center gap-1">
                          Paid
                        </p>
                    }
                    
                    {/* //* Qr Show Button*/}
                    {showQR &&  
                      <button onClick={handleShareQr} className="px-5 py-2.5 w-fit h-fit font-medium bg-zinc-50 hover:bg-zinc-100 hover:text-zinc-600 text-zinc-500 rounded-lg text-sm flex items-center gap-1"> QR
                            <MdQrCodeScanner className='text-xl'/>
                        </button>
                    }
                    
                    {/* //* Share Qr */}
                    {shareQR && (
                      <div
                          className="max-w-fit w-full mb-5 flex flex-row-reverse gap-2 relative"
                          style={{ zIndex: "5" }}
                        >
                          <div className='bg-red-500 text-white rounded-full p-2 h-fit absolute cursor-pointer'  onClick={() => setShareQR(false)}>
                              <IoCloseSharp className='text-lg'/>
                          </div>
                          <div className='p-0 m-0'>
                            
                             
                                <QRCode 
                                      value={booking?.short_video_uri !== null  ? booking?.short_video_uri : booking?.video_uri_wasabi ? booking?.video_uri_wasabi :booking?.video_uri_s3 ? booking?.video_uri_s3 : booking?.video_uri}
                                      size={160}
                                      quietZone={50}
                                      logoImage={assets?.droame_logo_green}
                                      logoWidth={80}
                                      logoOpacity={0.5}
                                      removeQrCodeBehindLogo={false}
                                      qrStyle="dots"
                                      eyeRadius={10}
                                      eyeColor={"#00B2B2"}
                                      id={booking_id}
                                      style={{ height: "auto", }}
                                      // bgColor={"#00B2B2"}
                                      fgColor={"#00B2B2"}
                                      // logoPadding={0}
                                    />
                            </div>
                        </div>
                      
                    )}

                     {/* // * Bought button */}
                      {user?.email === "retargeting_thrill@droame.com" && <div onClick={()=> setOpenBoughtModal(true)} className="cursor-pointer px-5 py-2.5 font-medium bg-blue-50 hover:bg-blue-100 hover:text-blue-600 text-blue-500 rounded-lg text-sm">
                          Bought
                      </div>}
                     
                     
                    {/* //* Qr Show Button* */}
                      {((booking?.retargeting_remark || booking?.retargeting?.remark) && user?.email === "retargeting_thrill@droame.com") &&  
                      <div className='w-full font-medium text-sm'>
                          <p><span className='font-semibold'>Remark : </span>{booking?.retargeting_remark ? booking?.retargeting_remark : booking?.retargeting?.remark}</p>
                      </div> }
   
                    </div>
                 </td> 

                 {mobileScreen ? modalOpen &&<PaymentCheckoutPage modalOpen={modalOpen} onCloseModal = {()=>{setModalOpen(false)}} booking={booking} groupPaymet={false} RefreshBookings={RefreshBookings}/> 
                   : modalOpen && <CustomModal modalOpen={modalOpen} onCloseModal = {()=>{setModalOpen(false)}} booking={booking} groupPaymet={false} RefreshBookings={RefreshBookings} />}

                </tr>
              )} 
            
              
               {openBoughtModal && <AreUConfirmModal modalOpen={openBoughtModal} onCloseModal = {()=>{setOpenBoughtModal(false)}} title={"Are you sure video is Bought?"} Icon={MdVideoLibrary} 
                executeFun={()=> handlePostRetargetingRemarks()}  shareLoading={shareLoading} boughtModal={true}/> }
              {feedbackModalOpen && <FeedbackModal modalOpen={feedbackModalOpen} onCloseModal = {()=>{setFeedbackModalOpen(false)}} booking={booking}  RefreshBookings={RefreshBookings}/>}
              {confirmShareModalOpen && <AreUConfirmModal modalOpen={setConfirmShareModalOpen} onCloseModal = {()=>{setConfirmShareModalOpen(false)}} title={"Are you sure you want to share the video?"} Icon={FaRegShareSquare} 
                executeFun={()=> handleShare()}  shareLoading={shareLoading}/>}
             {commentModalOpen &&  <CommentModal booking={booking}  modalOpen={commentModalOpen} onCloseModal = {()=>{setCommentModalOpen(false)}} customer_id={customer_id} booking_id={booking_id} RefreshBookings={RefreshBookings}/>}
            
   </>
  )
}

export default memo(TableRowComp);