import React from 'react'
import assets from '../../assets'
import "./logoLoader.css"
const LogoLoader = () => {
  return (
    <div className='loading-overlay'>
       <img className={`logo `} src={assets?.droame_logo_green} alt="Droame_log" />
    </div>
  )
}

export default LogoLoader