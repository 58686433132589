import React from 'react'
import { Link } from 'react-router-dom'
import assets from '../assets';

const NotFoundPage = () => {
    const userToken = localStorage.getItem('user_token');
    const locationName = localStorage.getItem('user_location');
  return (
    <div className='w-full h-[86vh] flex items-center justify-center'>
         <div className='flex container w-[90%] mx-auto items-center justify-between mmd:flex-col'>
            <div className='w-[50%] mmd:w-full mmd:p-0 mmd:mt-10 flex flex-col gap-5 '  style={{
                    backgroundImage: 'url("https://i.ibb.co/G9DC8S0/404-2.png")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover' // This is equivalent to object-fit: cover
            }}>
                            <h1 className="my-2 text-gray-800 font-bold text-2xl">
                                Looks like you've found the
                                doorway to the great nothing
                            </h1>
                            <p className="my-2 text-gray-800">Sorry about that! Please visit our Booking Page to get where you need to go.</p>
                             <Link to={`${userToken ? `/${locationName}/customer-video` : '/login'}`} className="my-2 w-fit border rounded md py-4 px-8 text-center bg-[#3F8786] text-white hover:bg-webGrenn focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">Take me there!</Link>
            </div>
            <div className='w-[40%] mmd:w-[60%] '>
                <img src={assets?.notFonund_drone} alt='Not_Found' className='object-cover w-full '/>
            </div>
        </div>
    </div>
  )
}

export default NotFoundPage