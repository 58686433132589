import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal";
import { FcDeleteDatabase } from "react-icons/fc";
import SimpleLogoLoader from './logoloader/SimpleLogoLoader';


const ReportModal = ({modalOpen , onCloseModal , reportBookings , reportLoading , focReport}) => {

    const [totalPurchased , setTotalPurchased]=useState(0);
    const [conversationRate , setConversationRate]=useState();

    useEffect(()=>{
        const totalUsers = reportBookings?.length;
        const boughtUsersCount = reportBookings?.reduce((acc, user) => acc + (user?.retargeting?.bought ? 1 : 0), 0);
        const conversionRate = (boughtUsersCount / totalUsers) * 100;
        setTotalPurchased(boughtUsersCount);
        setConversationRate(conversionRate);
    },[reportBookings]);

    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };
    

        // ** Call Api for Download Ticket PDF multiple cards Like Grid in one page
            const downloadPDF = () => {
                const input = document.getElementById('table-to-pdf');
                html2canvas(input)
                  .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    
                    // Define padding
                    const padding = 10; // 10 units of padding
                    
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth() - padding * 2;
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            
                    pdf.addImage(imgData, 'PNG', padding, padding, pdfWidth, pdfHeight);
                    pdf.save('table.pdf');
                  })
                  .catch((error) => {
                    console.error('Error generating PDF:', error);
                  });
              };

            const dateFormat=(dateString)=>{
              const date = new Date(dateString);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed
              const day = String(date.getDate()).padStart(2, '0');
              return `${day}-${month}-${year}`;
            }
            
       console.log("reportBookings :", reportBookings);
              
  return (
    <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Payment Modal" style={customStyles} ariaHideApp={false}>
       <div className={` m-0 flex flex-col gap-5 items-start rounded-md  px-4 py-10 text-gray-800 relative mx-auto z-50 w-[90vw] min-h-[50vh] max-h-[90vh]`}>

        {reportLoading ? <div className='w-full flex items-center justify-center h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SimpleLogoLoader logoWidth="80px"/> </div> : 
        !reportBookings || reportBookings?.length < 1 ? 
           <div className='flex flex-col items-center justify-center text-center w-full h-full mx-auto my-auto'>
            <FcDeleteDatabase className='text-7xl'/>
            <p>No Bookings Found for the Selected Criteria</p>
         </div>
         :  
        <>
         <div>
            <button onClick={downloadPDF} type="button" className="inline-flex cursor-pointer items-center rounded-lg border border-gray-400 bg-white py-2 px-3 text-center text-sm font-medium text-gray-800 shadow hover:bg-gray-100 focus:shadow">
            <svg className="mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" className=""></path>
            </svg>
            Export to PDF
            </button>
         </div>
        

             <div className="text-right mt-2 flex justify-end  w-full overflow-x-auto">

        <div  id="table-to-pdf" className="mt-3  rounded-xl  px-2 w-full">
           {focReport ? 
             <>
                <div className='flex gap-10 w-full items-center justify-between bg-blue-600 text-white rounded-xl overflow-x-auto'>
                    <div className='font-semibold text-xl p-5 rounded-[12px] '>
                            <p>Total FOC : <span className='font-bold text-2xl'>{reportBookings?.length}</span></p>
                    </div>
              </div>

            <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2 mt-5 ">
                <thead className=" border-b table-header-group justify-center">
                <tr className="">
                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">No.</td>
                    <td className="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3 text-left">Name</td>

                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">No.Of Customer</td>
                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Booking Date</td>
                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Payment Date</td>

                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Amount</td>

                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Payment Mode</td>

                    {/* <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Remark</td> */}
                </tr>
              
                </thead>

                <tbody className="border-gray-300 ">
                    {reportBookings?.map((booking,idx)=>(
                        <tr className="text-left" key={idx}>
                        <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{idx+1}</td>
                        <td className="whitespace-no-wrap py-4 text-left text-sm text-gray-600 sm:px-3 ">{booking?.name}</td>
                        <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{booking?.no_of_customers}</td>
                        <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{dateFormat(booking?.booking_creation_date)}</td>
                        <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{dateFormat(booking?.created_at)}</td>
                        <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell ">{booking?.final_amount}</td>
                        <td className="whitespace-no-wrap  py-4  text-sm font-normal text-gray-600 px-3 table-cell ">{booking?.payment_mode === "foc" ? "FOC" : booking?.payment_mode}</td>
                        {/* <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-500 px-3 table-cell">{booking?.retargeting?.remark}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
             </>

             : 
             <>
                 <div className='flex gap-10 w-full items-center justify-between bg-blue-600 text-white rounded-xl overflow-x-auto'>
               <div className='font-medium p-5 rounded-[12px] '>
                    <p>Retargeted Customers : <span className='font-bold text-2xl'>{reportBookings?.length}</span></p>
               </div>

               <div className='font-medium p-5'>
                    <p>Total Purchased : <span className='font-bold text-2xl'>{totalPurchased}</span></p>
               </div>

               <div className='font-medium p-5'>
                    <p>Conversation : <span className='font-bold text-2xl'>{conversationRate?.toFixed(2)}%</span></p>
               </div>
              
                 </div>

                <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2 mt-5 ">
                    <thead className=" border-b table-header-group justify-center">
                    <tr className="">
                        <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">No.</td>
                        <td className="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3 text-left">Name</td>

                        <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Phone No.</td>
                        <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Booking Date</td>
                        <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Retargeting Date</td>

                        <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Activity</td>

                        <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Bought</td>

                        <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3 text-left">Remark</td>
                    </tr>
                
                    </thead>

                    <tbody className="border-gray-300 ">
                        {reportBookings?.map((booking,idx)=>(
                            <tr className="text-left" key={idx}>
                            <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{idx+1}</td>
                            <td className="whitespace-no-wrap py-4 text-left text-sm text-gray-600 sm:px-3 ">{booking?.customer_name}</td>
                            <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{booking?.phone_number}</td>
                            <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{dateFormat(booking?.created_at)}</td>
                            <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell">{dateFormat(booking?.retargeting?.created_at)}</td>
                            <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-600 px-3 table-cell ">{booking?.sub_location_name}</td>
                            <td className="whitespace-no-wrap  py-4  text-sm font-normal text-gray-600 px-3 table-cell ">{booking?.retargeting?.bought ? "Yes" :"No"}</td>
                            <td className="whitespace-no-wrap  py-4 text-sm font-normal text-gray-500 px-3 table-cell">{booking?.retargeting?.remark}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
             </>
            }
           
        </div>


                {/* <button onClick={()=>{onCloseModal()}} id="confirm-delete-btn" className="inline-block w-auto px-4 py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm ml-2 order-2">
                    Cancel
                </button>
                <button id="confirm-cancel-btn" className=" inline-block w-auto px-4 py-2 bg-emerald-200 text-emerald-700 rounded-lg font-semibold text-sm  order-1">
                 Share
                </button> */}
            </div>
            
            </>   }        
       </div>
       </Modal>
  )
}

export default ReportModal