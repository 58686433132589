import React from 'react'

const CicleLoader = () => {
  return (
    <div className='w-full h-full bg-[#ffffff] bg-opacity-50 backdrop-blur-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30 flex items-center justify-center'>
                        <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-success motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status"></div>
                </div> 
  )
}

export default CicleLoader