const initialState = {
   loading: false,
   videos: [],
   searchResults: {},
   error: '',
   //date: TodayDate(), 
};

const videoReducer = (state=initialState, action) => {
    switch(action.type) {
        case 'FETCH_VIDEO_REQUEST':
            return {
               ...state,
               loading: true
            };
        case 'FETCH_VIDEO_SUCCESS':
            return {
                ...state,
                loading: false,
                videos: action.payload,
            };
        case 'FETCH_VIDEO_FAILURE':
            return {
               ...state,
               loading: false,
               error: action.payload,
            };
        default:
            return state; 
    }
};

export default videoReducer;